import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from "@rails/activestorage"

class Uploader {
  constructor(file, url, progressCallback, endCallback) {
    this.progressCallback = progressCallback;
    this.endCallback = endCallback;
    this.file = file;
    this.directUpload = new DirectUpload(this.file, url, this);
  }

  upload() {
    this.directUpload.create(this.endCallback)
  }

  // this is an implementation of the DirectUploadDelegate from ActiveStorage
  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress", this.progressCallback)
  }
}

export default class extends Controller {
  static targets = ["addBtn", "fileInput", "sendBtn", "previewList", "uploadList", "sizeAlert", "clearButton"]
  static values = { ghost: Boolean, maxSize: Number }

  connect() {
    this.smallImageTpl = this.previewListTarget.children[0].outerHTML;
    this.videoImageTpl = this.previewListTarget.children[1].outerHTML;
    this.originalSendBtnText = this.sendBtnTarget.textContent.trim();
    this.lockSendBtn();
    this.bigImage = document.querySelector("#job_result_form .display-result-image");
    this.currentActive = null;
    this.initInternalFileState();
  }

  initInternalFileState() {
    this.smallBlocks = {};
    this.filesBuffer = new DataTransfer();
    this.indexMap = {}; // { "index in filesBuffer" => "accumulatedIndex in smallBlocks" }, before deleting key == value
    this.filesCount = 0;
    this.previewListTarget.innerHTML = '';
    this.uploadedCount = 0;
    this.progressBarMap = {};
  }

  clearInputFile() {
    this.bigImage.querySelector('video').style.display = 'none';

    this.rejectFile(this.bigImage.dataset.index);
  }

  rejectFile(currentIndex) {
    this.smallBlocks[currentIndex]?.remove();
    delete this.smallBlocks[currentIndex];
    const bufIndex = this.getKeyByValue(this.indexMap, currentIndex);
    delete this.filesBuffer.items.remove(bufIndex)
    // from now filesBuffer items indexes adjusted

    const remainKeys = Object.keys(this.smallBlocks);
    if (remainKeys.length <= 1) {
      this.previewListTarget.style.display = "flex";
      this.bigImage.style.width = "100%";
      this.bigImage.style.backgroundImage = "";
    }
    if (remainKeys.length > 0) {
      this.setActiveImage(this.smallBlocks[remainKeys.sort()[0]]);
    } else {
      this.lockSendBtn();
      this.bigImage.style.display = "none";
    }

    /* Re-indexing logic:
      Assume:
        filesBuffer keys: [0, 1, 2, 3, 4]
        smallBlocks keys: [0, 1, 2, 3, 4] (indexes in data-index)
        indexMap: {0: 0, 1: 1, 2: 2, 3: 3, 4: 4}
        currentIndex: 2
      Then:
        filesBuffer keys: [0, 1, 2, 3]
        smallBlocks keys: [0, 1, 3, 4]
        indexMap: {0: 0, 1: 1, 2: 3, 3: 4}
    */

    // shift values in indexMap
    let i = 0;
    Object.keys(this.indexMap).forEach(((key) => {
      const keyInt = parseInt(key);
      if (parseInt(key) >= parseInt(bufIndex)) {
        i += 1;
        this.indexMap[key] = parseInt(currentIndex) + i;
      };
    }).bind(this))
    delete this.indexMap['' + (Object.keys(this.indexMap).length - 1)]
    // this.fileInputTarget.files = this.filesBuffer.files;
    const input = $(`input[type=hidden][data-index=${currentIndex}]`, this.previewListTarget)
    if (input.length){
      input.remove();
      this.uploadedCount -= 1;
    }
    $(`div.row[data-index=${currentIndex}]`, this.uploadListTarget).remove();
    this.filesCount -= 1;
  }

  getKeyByValue(object, value) {
    const val = parseInt(value)
    return Object.keys(object).find(key => object[key] === val);
  }

  browseFiles() {
    this.fileInputTarget.click();
  }

  loadPreview() {
    /* Indexing logic:
      Assume:
        filesBuffer keys: [0, 1]
        smallBlocks keys: [2, 4] (indexes in data-index)
        IndexMap: {0: 2, 1: 4}
        this.filesCount: 5 (files with indexes 0,1,3 were removed)
        this.fileInputTarget.files.length == 3 (newly selected files by user)
      Then:
      filesBuffer keys: [0, 1, 2, 3, 4]
      smallBlocks keys : [2, 4, 5, 6, 7]
        IndexMap: {0: 2, 1: 4, 2: 5, 3: 6, 4: 7}
        this.filesCount: 5+3 = 8;
    */

    if (!this.fileInputTarget.multiple) {
      this.initInternalFileState();
    }

    const commonIndex = this.filesCount,
      bufIndex = this.filesBuffer.items.length;
    let hasBig = false;
    $(this.fileInputTarget.files).each((index, file) => {
      const shiftedIndex = commonIndex + index;
      this.indexMap[bufIndex + index] = shiftedIndex;
      if (this.ghostValue && this.checkMaxFileSize(file)) {
        hasBig = true;
        return false;
      } else {
        this.filesBuffer.items.add(file);  
      };
      this.directUploadFile(file, shiftedIndex);
      const reader = new FileReader();
      reader.addEventListener("load", (event) => {
        const url = event.target.result;
        const asVideo = /^data\:video/.test(url);
        const newSmallBlock = $(asVideo ? this.videoImageTpl : this.smallImageTpl).appendTo(this.previewListTarget)[0];
        if (asVideo) {
          let blobURL = window.URL.createObjectURL(file);
          this.bigImage.style.backgroundImage = "";
          this.bigImage.querySelector('video').style.display = '';
          this.bigImage.querySelector('video').src = blobURL;
          this.bigImage.style.display = "block";
          this.bigImage.style.height = "auto";
          newSmallBlock.src = blobURL;
        } else {

          newSmallBlock.style.backgroundImage = `url(${url})`;
        }
        newSmallBlock.dataset.index = shiftedIndex;
        if (index == 0) { // show the first image from selection on each file select action
          this.setActiveImage(newSmallBlock)
        }
        this.smallBlocks[shiftedIndex] = newSmallBlock;
      });
      reader.readAsDataURL(file);
    })
    if (!hasBig) {
      this.filesCount += this.fileInputTarget.files.length;
    }

    if (this.filesCount > 1) {
      this.previewListTarget.style.display = "flex";
      this.bigImage.style.width = "82%";
    }

    // console.log('on file add', this.indexMap);
    // this.fileInputTarget.files = this.filesBuffer.files;
    
    // you might clear the selected files from the input
    this.fileInputTarget.value = null
    
  }

  trimFileName(fileName) {
    const parts = fileName.split('.');
    let firstPart = parts.slice(0, parts.length - 1).join('.');
    if (firstPart.length > 10) {
      firstPart = firstPart.slice(0, 10) + '…';
    }
    return firstPart + '.' + parts[parts.length - 1];
  }

  directUploadFile(file, index) {
    this.clearButtonTarget.style.display = "none";
    const url = this.fileInputTarget.dataset.directUploadUrl
    const size = (file.size / 1024 / 1024).toFixed(2);
    $(this.uploadListTarget).append(`<div class="row" data-index="${index}"><div class="col-12"><span class="progress-bar">${this.trimFileName(file.name)} (${size} Mb)</span></div></div>`)
    this.progressBarMap[index] = $(`div.row[data-index="${index}"] span.progress-bar`, this.uploadListTarget)[0];
    const endCallback = (error, blob) => {
      if (error) {
        // TODO: show error message & remove the file from the list
        console.log("Upload Error:", error)
        this.rejectFile(index);
      } else {
        this.uploadedCount += 1;
        const hiddenField = document.createElement('input')
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("value", blob.signed_id);
        hiddenField.setAttribute("data-index", index);
        hiddenField.name = this.fileInputTarget.name;
        this.previewListTarget.appendChild(hiddenField)

      }
      if (this.filesCount == this.uploadedCount) {
        this.clearButtonTarget.style.display = "block";
        this.unlockSendBtn();
      }
    }

    const progressCallback = (event) => {
      const percent = Math.round((event.loaded / event.total) * 100);
      this.progressBarMap[index].style.width = `${percent}%`;
      //console.log("upload progress: ", percent)
    }

    this.lockSendBtn(this.sendBtnTarget.dataset.uploadingText);

    new Uploader(file, url, progressCallback, endCallback).upload();
  }

  smallImageClicked(event) {
    if (this.clearButtonTarget.style.display === 'block') {
      this.setActiveImage(event.target);
    }
  }

  setActiveImage(target) {
    if (target == this.currentActive) { return };
    if (this.currentActive) {
      this.currentActive.classList.remove("active");
    }
    const asVideo = target.tagName == "VIDEO";
    if (asVideo) {
      this.bigImage.style.backgroundImage = "";
      this.bigImage.querySelector('video').style.display = '';
      this.bigImage.querySelector('video').src = target.src;
      this.bigImage.style.height = "auto";
    } else {
      this.bigImage.querySelector('video').style.display = "none";
      this.bigImage.style.height = "";
      this.bigImage.style.backgroundImage = target.style.backgroundImage;

    }
    this.bigImage.style.display = 'block';
    this.bigImage.dataset.index = target.dataset.index;

    this.currentActive = target;
    this.currentActive.classList.add('active');

  }

  onSubmitClick() {
    this.lockSendBtn(this.sendBtnTarget.dataset.submittingText);
    document.getElementById('job_result_form').submit();
    return false;
  }

  unlockSendBtn() {
    this.sendBtnTarget.disabled = false;
    this.sendBtnTarget.classList.remove("disabled");
    this.sendBtnTarget.textContent = this.originalSendBtnText;
  }

  lockSendBtn(text) {
    this.sendBtnTarget.disabled = true;
    this.sendBtnTarget.classList.add("disabled");
    this.sendBtnTarget.textContent = text || this.originalSendBtnText;
  }


  checkMaxFileSize(file) {
    if (file.size > this.maxSizeValue) {
      this.sizeAlertTarget.style.display = "block";
      return true;
    } else {
      this.sizeAlertTarget.style.display = "none";
      return false;
    }
  }

  resetError() {
    if (this.ghostValue) {
      this.sizeAlertTarget.style.display = "none";
    }
  }

}
