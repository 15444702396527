import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { 
    id: { type: Number, default: -1 }, 
    title: { type: String, default: "?"},
    refreshInterval: { type: Number, default: 1000 } 
  }

  static targets = ["title"]

  initialize() {

    // TODO: move text animation to own stimulus controller
    this.animDurationMs = 1000
    this.animText = anime({
      targets: this.titleTarget,
      rotateX: '720',
      duration: this.animDurationMs,
      loop: false,
      direction: 'alternate',
      easing: 'easeInOutCubic',
      autoplay: false
    })
    //console.log("animText initalized for job ", this.idValue)
  }

  connect() {
    //console.log("job connected with id", this.idValue )



  }

  disconnect() {
    //console.log("job disconnect with id", this.idValue )
  }

  updateData(event) {
    var jobs = event.detail.jobs;
    jobs.forEach((job) => {
      if (job.id == this.idValue) {
        this.titleValue = job.title
      }
    })
  }


  titleValueChanged(value, previousValue) {
    // console.log("titleValueChanged", value, previousValue, this.titleTarget)
    var textEle = this.titleTarget

    setTimeout(function() {
      //console.log("delayed update text to", value, textEle)
      $(textEle).html(value)
    }, this.animDurationMs / 2)

    // trigger animation
    if (this.animText) {
      this.animText.play()
    }
  }



}
