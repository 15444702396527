import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { 
  }

  initialize() {

    console.log("init tilt")
    var options = {
      glare: true,
      maxGlare: .1
    }
    /* tilt.js with jquery
    $(this.element).tilt(options)
    */

    VanillaTilt.init(this.element, options);

    // // Get values of instance
    var values = this.element.vanillaTilt.getValues()
    console.log("tilt values", values)
  }

  connect() {
    console.log("connected tilt")


  }

  disconnect() {
    console.log("disconnected tilt")
  }



}
