import {Controller} from 'stimulus';

export default class extends Controller {

    connect() {
        const id = this.data.get('id');
        const player = new Plyr(`#audio-${id}`, {controls: ['play', 'progress', 'mute' ]});
    }

    toggle() {
        let id = this.data.get('id')
        let player = document.getElementById(`audio-player-${id}`);
        let hidden = player.style.display == 'none'
        player.style.display = hidden ? 'inline-flex' : 'none'
    }
}
