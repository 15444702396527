import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["pausedCheckbox", "pausedMessage", "form"]

  pausedOnChange(event) {
    const isPaused = this.pausedCheckboxTarget.checked
    if(isPaused) {
      this.pausedMessageTarget.classList.remove('hidden')
    } else {
      this.pausedMessageTarget.classList.add('hidden')
      this.submit()
    }
  }

  submit() {
    if (confirm("Are you sure?") == true) {
      this.formTarget.submit()
    }
  }
}
