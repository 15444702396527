import {Controller} from 'stimulus';

export default class extends Controller {
    static targets = ["toToggle", "checkbox"]

    connect() {
      this.toggle()
    }

    toggle() {
      let toggleable = this.toToggleTarget
      let switched = this.checkboxTarget.checked
      let border = this.element
      if (switched) {
          border.style.border = '1px solid rgba(0, 0, 0, 0.125)'
          toggleable.style.display = 'block'
      } else {
          border.style.border = '0'
          toggleable.style.display = 'none'
      }
    }
}
