import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["subfilters", "order", "btnclear", "btnreverse", "filterEnablingCheckbox"]

  connect() {
    var theThis = this;

    // TODO / NOTE racing condition can happen here ?

    $(this.filterEnablingCheckboxTargets).each(function () {
      theThis.toggleSubfilterOptions(this);
    })

    Sortable.create(this.subfiltersTarget, {
      handle: ".subfilter-title",
      delay: 500,
      delayOnTouchOnly: true,
      // filter: ".subfilter-options", // NOTE: this will also diable general pointer events -.-
      onEnd: function (/**Event*/evt) {
        theThis.updateOrderValue();
      },
    });
  }

  updateOrderValue() {
    var orderString = "";
    var theThis = this;
    $(theThis.subfiltersTarget).find("[data-subfilter-column-name]").each(function () {
      var column_name = $(this).attr("data-subfilter-column-name");
      console.log(column_name);
      orderString += column_name + ",";
    });

    //console.log("set order string",orderString);
    $(theThis.orderTarget).val(orderString);
  }

  toggleSubfilter(event) {
    this.toggleSubfilterOptions(event.target);
  }

  toggleSubfilterOptions(target) {
    const subfilterId = target.dataset.id;
    $(`#collapseSubfilter${subfilterId}`).toggle(target.checked);
  }

  clearFilters() {
    //console.log("clicked clearFilters", this)

    $(this.filterEnablingCheckboxTargets).each(function () {
      $(this).prop("checked", false);

      // TODO: do this for all subcheckboxes, selects and range double_sliders ..
    })
  }

  reverseFilters() {
    //console.log("clicked reverseFilters", this)

    var list = this.subfiltersTarget;
    var listItems = $(list).children('div');
    $(list).append(listItems.get().reverse());
    this.updateOrderValue();
    // TODO ... even needed ?
    // if needed something like this:
    /*
    https://stackoverflow.com/questions/5347839/jquery-reversing-the-order-of-child-elements/5347882
    */
    // this.updateOrderValue();
  }

  disconnect() {

  }

}
