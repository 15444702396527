import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    const element = this.element;
    $(element).on('change', 'input[role=switch]', function () {
      let ids = []
      $('input[role=switch]:checked', element).each(function () {
        ids.push(this.getAttribute('data-id'))
      })
      $.ajax({
        type: 'POST',
        dataType: 'script',
        url: `/jobs/${this.getAttribute('data-job-id')}/choose_creators`,
        data: {
          creator_job_state_ids: ids,
          sort_by: $("#sort :selected").val(),
        }
      })
    })
  }
}
