import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { owner: String }

  connect() {
    let user_id = document.getElementById("chat_messages").getAttribute("data-user-id");
    if(user_id == this.ownerValue){
      this.element.classList.add("msg-mine");
      this.element.classList.remove("msg-other");
    }
    else{
      this.element.classList.remove("msg-mine");
      this.element.classList.add("msg-other");
    }
  }
}
