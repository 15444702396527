import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "infoPanel", "showButton" ]

  hide() {
    this.infoPanelTarget.style.display = 'none'
    this.showButtonTarget.style.display = ''
  }

  show() {
    this.infoPanelTarget.style.display = ''
    this.showButtonTarget.style.display = 'none'
  }
}