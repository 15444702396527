import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { disabledAttribute: String, attribute: String }
  static targets = ["confirmation"]

  connect() {
    if(!this.hasDisabledAttributeValue){
      this.disabledAttributeValue = "data-confirm-disabled";
    }
    if(!this.hasAttributeValue){
      this.attributeValue = "data-confirm";
    }
  }

  enable(element){
    element.setAttribute(this.attributeValue, element.getAttribute(this.disabledAttributeValue));
    element.setAttribute("data-bs-toggle", "modal");
  }

  changed(){
    this.confirmationTargets.forEach(element => this.enable(element));
  }
}
