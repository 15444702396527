import { Controller } from "@hotwired/stimulus"
import * as bootstrap from 'bootstrap'

export default class extends Controller {

  connect() {
    this.initTooltips();
  }

  initTooltips(){
    // we need to wait for the DOM to be ready in some cases
    setTimeout(() => {
      new bootstrap.Tooltip(document.body, {
          selector: '[data-bs-toggle="tooltip"]'
      })
    }, 1000)
  }

}
