import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["mainImage", "mainVideo", "rollParent","referenceButton", "deleteButton" ]
  static values = { displayedAtOnce: Number }

  setMain(event) {
    this.setCenterPiece(event.target);
  }

  setCenterPiece(element){
    if(element.getAttribute("data-media-type") == "video"){
      this.mainImageTarget.classList.add("hidden");
      this.mainVideoTarget.classList.remove("hidden");
      this.mainVideoTarget.src = element.getAttribute("data-src");
    }
    if(element.getAttribute("data-media-type") == "image"){
      this.mainVideoTarget.classList.add("hidden");
      this.mainImageTarget.classList.remove("hidden");
      this.mainImageTarget.src = element.getAttribute("data-src");
    }
    this.referenceButtonTarget.setAttribute("data-media-id", element.getAttribute("data-media-id"));
    this.referenceButtonTarget.setAttribute("data-src", element.src);
    this.deleteButtonTarget.setAttribute("href", element.getAttribute("data-delete-path"));
  }

  clearCenterPiece(){
    this.mainVideoTarget.src = "";
    this.mainImageTarget.src = "";
    this.referenceButtonTarget.setAttribute("data-media-id", "");
    this.referenceButtonTarget.setAttribute("data-src", "");
    this.deleteButtonTarget.setAttribute("href", "");
    this.element.classList.add("hidden");
  }

  moveLeft(){
    this.index = Math.max(0, this.index - 1);
    this.move();
  }

  moveRight(){
    this.index = Math.max(0, Math.min(this.rollParentTarget.children.length - 1, this.index + 1));
    this.move();
  }

  move(){
    this.setCenterPiece(this.rollParentTarget.children[this.index].children[0].children[0]);
    let minShowIndex = Math.min(Math.max(this.index, 0), this.rollParentTarget.children.length - this.displayedAtOnceValue);

    for(var i = 0; i < this.rollParentTarget.children.length; i++){
      if(i >= minShowIndex && i < minShowIndex + this.displayedAtOnceValue){
        this.rollParentTarget.children[i].classList.remove("hidden");
      }
      else{
        this.rollParentTarget.children[i].classList.add("hidden");
      }

    }
  }

  connect() {
    this.index = 0;
    var theThis = this;
    var callback = (mutationList, observer) => {
      if(mutationList.length > 0 && mutationList[0].addedNodes.length > 0){
        theThis.setCenterPiece(mutationList[0].addedNodes[0].children[0].children[0]);
        theThis.element.classList.remove("hidden");
        theThis.index = 0;
        theThis.move();
      }
      if(mutationList.length > 0 && mutationList[0].removedNodes.length > 0){
        if(theThis.rollParentTarget.children.length > 0 && theThis.rollParentTarget.children[0]){
          theThis.setCenterPiece(theThis.rollParentTarget.children[0].children[0].children[0]);
          theThis.index = 0;
          theThis.move();
        }
        else{
          theThis.clearCenterPiece();
        }
      }

    };

    this.observer = new MutationObserver(callback);
    this.observer.observe(this.rollParentTarget, { childList: true });
    if(this.rollParentTarget.children.length > 0){
      this.move();
    }
  }

  disconnect(){
    this.observer.disconnect();
  }
}
