import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    haveShipping: Boolean
  }

  connect() {
    this.toggle()
  };

  validate(e) {
    if (!e.target.checked) {
        if (this.haveShippingValue) {
          $('#allow-shipping-error').removeAttr('hidden');
          $('#allow-shipping-error').show();
          e.target.checked = true;
          $('#allow-shipping-error').delay(5000).hide('slow');
        }
      }
  };

  toggle() {
    let address = document.getElementById('address-fields');
    let switched = document.getElementById('creator_allow_shipping').checked;
    let border = document.getElementById('address-border')
    if (switched) {
      border.style.border = '1px solid rgba(0, 0, 0, 0.125)'
      address.style.display = 'block'
    } else {
      border.style.border = '0'
      address.style.display = 'none'
    }
  }

}
