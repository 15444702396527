import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  keypress(e){
    var form = this.element;
    if(e.which === 13 && !e.shiftKey) {
        e.preventDefault();
        let event = document.createEvent("Event");
        event.initEvent("submit", true, true);
        form.dispatchEvent(event);
      }
  }
}
