import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ "quantity", "price", "total" ]

  connect() {
    this.calculate();
  }

  calculate() {
    this.totalTarget.value = this.quantityTarget.value * this.priceTarget.value;
  }

}