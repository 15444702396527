import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  remove(event){
    let mediaId = this.element.getAttribute("data-media-id");
    this.element.remove();
    let hiddenField = document.querySelector("#reference-media-hidden-fields").querySelector("[data-media-id='" + mediaId + "']");
    hiddenField.remove();
  }
}
