import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = [ "form", "formats", "totals", "sum" ]
  static values = {
    mfSize: Number
  }

  connect() {
  }

  submitForm(event) {
    const button = this.formTarget.querySelector('[type="submit"]');
    const formData = new FormData(this.formTarget)
    const formElements = Array.from(formData.entries());
    let invalid = formElements.find(el => el[1] === '0.0');
    if (invalid) {
      event.preventDefault();
      event.stopPropagation();
      this.showError();
      return
    }
    button.value = button.dataset.loadingText;
  }

  showError() {
    let errorMessage = document.getElementById('price-error')
    errorMessage.style.display = 'block'
    const button = this.formTarget.querySelector('[type="submit"]');
    const media_inputs = this.formTarget.querySelector('.media_upload__input');
    button.removeAttribute('data-disable-with');
    button.removeAttribute('disabled');
    media_inputs.removeAttribute('disabled');
  }

}
