import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content"]

  reply(event){
    let replyContent = $("#reply-content")[0];
    replyContent.innerText = this.contentTarget.innerText;
    $("#reply-container")[0].style.display = "flex";
    let replyHiddenField = $("#reply-to-field")[0];
    replyHiddenField.value = this.element.getAttribute("data-message-id");
  }

  removeReply(){
    let replyContent = $("#reply-content")[0];
    replyContent.innerText = "";
    $("#reply-container").hide();
    let replyHiddenField = $("#reply-to-field")[0];
    replyHiddenField.value = "";
  }
}
