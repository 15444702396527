import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    stateId: String
  }

  connect() {
  };

  good(e) {
    if (!e.target.checked) {
      this.setRate(this.stateIdValue, 'good');
    }
  };

  neutral(e) {
    if (!e.target.checked) {
      this.setRate(this.stateIdValue, 'neutral');
    }
  };

  setRate(id, rate) {
    $.ajax({
      type: 'POST',
      dataType: 'script',
      url: `/creator_job_states/${id}/rate`,
      data: {
        rating: rate, many_results: 'true'
      }
    })
  }
}