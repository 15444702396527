import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["likes", "comments"]

  connect() {
  };

  switchData(event) {
    const urlParams = new URLSearchParams(window.location.search);

    let jobs = urlParams.get('selected_job_ids');
    if (jobs==null) {jobs = urlParams.getAll('selected_job_ids[]')};

    let metrics = [];
    if (this.likesTarget.checked == true) {metrics.push('likes')};
    if (this.commentsTarget.checked == true) {metrics.push('comments')};
    if (metrics.length == 0) {metrics.push('none')};
    
    $.ajax({
      type: 'get',
      dataType: 'script',
      url: 'reporting',
      data: {
        metrics: metrics,
        selected_job_ids: jobs,
        tab: 'graph'
      }
    })
  };
}
