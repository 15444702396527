function parseFacebookToken(url) {
  var anchor_value;
  var params = {};
  var stripped_url = url.split("#");
  if (stripped_url.length > 1) {
    anchor_value = stripped_url[1];

    anchor_value.split("&").forEach((element)=> {
      var param = element.split("=")
      if (param.length > 1) {
        params[param[0]] = param[1]
      }
    })

    $.ajax({
      type: 'POST',
      dataType: 'script',
      url: `/creator/set_facebook_token`,
      data: {
        access_token: params['access_token'],
        data_access_expiration_time: params['data_access_expiration_time'],
        expires_in: params['expires_in']
      }
    })

  } else {

    window.location.href = '/'

  }
};

window.parseFacebookToken = parseFacebookToken