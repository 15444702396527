import { convertRemToPixels, convertPixelsToRem, lerp } from '../packs/helpers';
import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  connect(){
    this.element.rows = 1;
    var theThis = this;
    let form = this.element.closest('form');
    form.addEventListener("submit", function(){
        theThis.reset();
    });
  }

  reset(){
    this.element.rows = 1;
  }

  getScrollHeight(elm){
    var savedValue = elm.value
    elm.value = ''
    elm._baseScrollHeight = elm.scrollHeight
    elm.value = savedValue
  }

  onExpandableTextareaInput(event){
    let elm = event.srcElement;
    var minRows = elm.getAttribute('data-min-rows')|0, rows;
    var maxRows = elm.getAttribute('data-max-rows');
    var rowHeight = elm.getAttribute('data-row-height');
    !elm._baseScrollHeight && this.getScrollHeight(elm)

    elm.rows = minRows
    rows = Math.ceil((elm.scrollHeight - elm._baseScrollHeight) / convertRemToPixels(1.1))
    elm.rows = Math.min(maxRows, minRows + rows);
  }
}
