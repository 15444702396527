import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["variableItem", "display", "base", "modifier", "modifiedPrice", "expensesTotal", "fixedItem", "displayVat", "displayWithVat","displayBeforeVatAfterExpenses","displayVatAfterExpenses","displayAfterVatAfterExpenses","left","right","center"]
  static values = {
    vatRate: { type: Number, default: 0 }, taskBaseWages: {type: Array, default: []}
  }

  connect() {
    this.updateTotal();
  }

  itemTargetConnected(element) {
    this.updateTotal();
  }

  itemTargetDisconnected(element) {
    this.updateTotal();
  }


  updateTotal(){
    let baseValue = Number(this.baseTarget.textContent);
    let modifier = this.modifierTarget.value / 100
    baseValue = baseValue * modifier ;
    let taskValues = []
    this.taskBaseWagesValue.forEach(element => taskValues.push(this.round(this.round(element * modifier/100 * (1 + this.vatRateValue),5) / (1 + this.vatRateValue), 5)));
    baseValue = taskValues.reduce((partialSum, a) => partialSum + a, 0);
    let total_expenses = 0;
    let total_before_expenses = baseValue;
    if(this.hasModifiedPriceTarget){
      this.modifiedPriceTarget.textContent = this.delimiter(this.round(baseValue,5).toFixed(2));
    }
    for(var i = 0; i < this.variableItemTargets.length; i++){
      total_expenses += Math.round((Number.EPSILON + Number(this.variableItemTargets[i].value) * 100)) /100;
    }
    for(var i = 0; i < this.fixedItemTargets.length; i++){
      total_expenses += Number(this.fixedItemTargets[i].textContent.replace(/'/, ''));
    }
    if(this.hasExpensesTotalTarget){
      this.expensesTotalTarget.textContent = this.delimiter(this.round(total,5).toFixed(2));
    }
    total_before_expenses = this.round(total_before_expenses,5)
    this.displayTarget.textContent = this.delimiter(total_before_expenses.toFixed(2));
    let vat_values = []
    this.taskBaseWagesValue.forEach(element => vat_values.push(element * modifier/100 * this.vatRateValue));

    let total_with_vat_values = []
    this.taskBaseWagesValue.forEach(element => total_with_vat_values.push(this.round(element * modifier/100 * (1 + this.vatRateValue),5)));

    // let total_with_vat = this.round(total_with_vat_values.reduce((partialSum, a) => partialSum + a, 0),5);
    // let total_vat = total_with_vat - total_before_expenses;
    //this.displayVatTarget.textContent = this.delimiter(this.round(total_vat,5).toFixed(2));
    //this.displayWithVatTarget.textContent = this.delimiter(total_with_vat.toFixed(2));
    // let total_after_expenses_after_vat = total_with_vat + total_expenses;
    //this.displayVatAfterExpensesTarget.textContent = this.displayVatTarget.textContent;
    //this.displayAfterVatAfterExpensesTarget.textContent =  this.delimiter(this.round(total_after_expenses_after_vat,5).toFixed(2));
    this.displayBeforeVatAfterExpensesTarget.textContent = this.delimiter(this.round(baseValue + total_expenses,5).toFixed(2));

  }

  delimiter(number) {
    return number.replace(/\B(?=(\d{3})+(?!\d))/g, "'")
  }

  round(number, increment){
    number = (number + Number.EPSILON) * 100
    number = increment * Math.floor(( number + increment - 1) / increment)
    return Math.round(number) / 100
  }

  elementAdded(){

  }

  elementRemoved(){
    this.updateTotal();
  }

  changePostType(event){
    const changeSliderEvent = new CustomEvent("update-slider")
    let newBaseValue = Number(event.target.dataset.wague/100).toFixed(2)
    this.baseTarget.textContent = newBaseValue
    this.centerTarget.textContent = `CHF ${newBaseValue}`
    this.leftTarget.textContent = `CHF ${Number(newBaseValue / 2).toFixed(2)}`
    this.rightTarget.textContent = `CHF ${Number(newBaseValue * 2).toFixed(2)}`
    this.taskBaseWagesValue = [event.target.dataset.wague]
    this.updateTotal();
    window.dispatchEvent(changeSliderEvent)
  }
}
