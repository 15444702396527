import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["mainImage", "mainVideo", "rollParent" ]
  static values = { displayedAtOnce: Number }
  connect(){
    this.index = 1;
    this.halfDisplayedAtOnce = Math.floor(this.displayedAtOnceValue/2);
  }

  setMain(event) {
    this.index = Array.prototype.indexOf.call(this.rollParentTarget.children, event.target.parentElement);
    this.index = Math.max(1, this.index);
    this.index = Math.min(this.rollParentTarget.children.length - this.displayedAtOnceValue - 1, this.index);

    this.switchMain(event.target);
    this.move();
  }

  switchMain(element){
    if(element.getAttribute("data-media-type") == "video"){
      this.mainImageTarget.classList.add("hidden");
      this.mainVideoTarget.classList.remove("hidden");
      this.mainVideoTarget.src = element.getAttribute("data-src");
    }
    if(element.getAttribute("data-media-type") == "image"){
      this.mainVideoTarget.classList.add("hidden");
      this.mainImageTarget.classList.remove("hidden");
      this.mainImageTarget.src = element.src;
    }
  }

  moveLeft(){
    this.index = Math.max(0, this.index - 1);
    this.move();
    this.switchToMiddle();
  }

  moveRight(){
    this.index = Math.min(this.rollParentTarget.children.length - this.displayedAtOnceValue, this.index + 1);
    this.move();
    this.switchToMiddle();
  }

  switchToMiddle(){
    var middleIndex = Math.floor(this.index + this.displayedAtOnceValue / 2);
    var middleChild = this.rollParentTarget.children[middleIndex].children[0];

    this.switchMain(middleChild);
  }


  move(){
    let minShowIndex = Math.min(Math.max(this.index, 1), this.rollParentTarget.children.length - 1 - this.displayedAtOnceValue);
    for(var i = 0; i < this.rollParentTarget.children.length; i++){
      if((i >= minShowIndex && i < minShowIndex + this.displayedAtOnceValue) || i == 0 || i == this.rollParentTarget.children.length - 1){
        this.rollParentTarget.children[i].classList.remove("hidden");
      }
      else{
        this.rollParentTarget.children[i].classList.add("hidden");
      }

    }
  }
}
