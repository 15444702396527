import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  removeReferences(event){
    let replyContent = $("#reply-content")[0];
    replyContent.innerText = "";
    $("#reply-container").hide();
    let replyHiddenField = $("#reply-to-field")[0];
    replyHiddenField.value = "";
    let parent1 = document.querySelector("#reference-media-hidden-fields");
    let inputs = parent1.querySelectorAll("input");
    for(let i = 0; i < inputs.length; i++){
      inputs[i].remove();
    }
    let parent2 = document.querySelector("#media-reference-container");
    let divs = parent2.querySelectorAll("div");
    for(let i = 0; i < divs.length; i++){
      divs[i].remove();
    }
  }
}
