import Rails from "@rails/ujs";
import AuthController from "./auth_controller"

export default class extends AuthController {
  static targets = ["email", "password", "webauthnButton"]
  static values = {
    callback: String,
    webauthn: String,
    resourceName: String
  }

  connect() {
    this.defaultActionUrl = this.element.getAttribute("action")
  }

  webauthnLogin(event) {
    event.preventDefault()

    this.element.setAttribute("data-remote", true)
    this.element.setAttribute("data-turbo", false)
    this.element.setAttribute("action", this.webauthnValue)

    Rails.fire(this.element, 'submit')
  }

  defaultLogin(event) {
    event.preventDefault()

    this.element.setAttribute("data-remote", false)
    this.element.setAttribute("data-turbo", true)
    this.element.setAttribute("action", this.defaultActionUrl)

    Rails.fire(this.element, 'submit')
  }

  async checkEmail(event) {
    const email = event.target.value
    if (email && this.validateEmail(email)) {
      let response = await fetch(`/webauthn/credentials.json?email=${email}&resource_name=${this.resourceNameValue}`)
      if (response.ok) {
        let { credential } = await response.json()

        if (credential) {
          this.webauthnButtonTarget.classList.remove('d-none')
          this.webauthnLogin(event)
        } else {
          this.webauthnButtonTarget.classList.add('d-none')
        }
      }
    }
  }

  validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  }
}
