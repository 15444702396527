import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  update(){
    if(this.element.checked) {
      $("#preview_"+this.element.id).removeAttr('hidden');
      $("#preview_"+this.element.id).show();
    } else {
      $("#preview_"+this.element.id).hide();
    }
  }

}