import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["codesField","fileField"]

  connect() {
    this.reader = new FileReader();
    var theThis = this;
    this.reader.addEventListener('load', (event) => {
      theThis.codesFieldTarget.value = event.target.result;
      console.log(event.target.result);
    });
  }

  uploaded(){
    console.log("uploaded");

    this.reader.readAsText(this.fileFieldTarget.files[0])
  }
}
