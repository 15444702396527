import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["checkbox", "rangemax", "rangemin"]

  connect() {
  }

  reset(){
    if(this.hasCheckboxTarget){
      this.checkboxTargets.forEach(element => element.checked = true)
    }
    if(this.hasRangemaxTarget){
      this.rangemaxTarget.value = this.rangemaxTarget.getAttribute("max")
      var event = new Event('input');
      this.rangemaxTarget.dispatchEvent(event);
    }
    if(this.hasRangeminTarget){
      this.rangeminTarget.value = this.rangeminTarget.getAttribute("min")
      var event = new Event('input');
      this.rangeminTarget.dispatchEvent(event);
    }
  }

}
