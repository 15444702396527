import {Controller} from 'stimulus';

export default class extends Controller {
    static targets = ["formats"]
    static values = {
        mfSize: Number
    }

    connect() {
        this.checkFormats()
        this.toggle()
    }

    toggle() {
        let mediaFormats = document.getElementById('media-formats');
        let switched = document.getElementById("switch-creator-format").checked
        mediaFormats.style.display = switched ? 'none' : 'block'
    }

    show(event) {
        let id = event.target.name.replace(/[^0-9]/g, '')
        let value = event.target.value
        this.resolutionShow(id, value.includes('Custom'))
        this.soundShow(id, value.includes('Video'))
    }

    resolutionShow(id, show) {
        let resolution = document.getElementById(`resolution_${id}_`);
        let breaker = document.getElementById(`break_${id}_`);
        if (show) {
            breaker.classList.remove("d-none");
            resolution.style.display = 'inline-flex';
        } else {
            breaker.classList.add("d-none");
            resolution.style.display = 'none';
        }
    }

    soundShow(id, show) {
        let sound = document.getElementById(`sound_${id}_`)
        sound.style.display = show ? 'block' : 'none'
    }

    addFormat(event) {
        event.preventDefault()
        this.mfSizeValue += 1;
        const regex = /\[\d+]/g;
        const regex_id = /_\d+_/g;
        const new_name = `[${this.mfSizeValue}]`;
        const new_id = `_${this.mfSizeValue}_`;
        let firstElement = this.formatsTarget.firstElementChild
        const newFieldset = firstElement.outerHTML.replace(regex, new_name).replace(regex_id, new_id).replace(/:.+?</, '<') // Last replace need to remove downloaded filename from label
        this.formatsTarget.insertAdjacentHTML("beforeend", newFieldset);
        this.checkFormats()
    }

    checkFormats() {
        let selectors = document.getElementsByClassName('format')
        for (let item of selectors) {
            let id = item.id.replace(/[^0-9]/g, '');
            let value = item.value
            if (value) {
                this.resolutionShow(id, value.includes('Custom'))
                this.soundShow(id, value.includes('Video'))
            }
        }
        this.checkButtons()
    }

    checkButtons() {
        let removeButtons = document.getElementsByClassName('remove-button')
        if (removeButtons.length === 1) {
            removeButtons[0].classList.add("d-none");
        } else if (removeButtons.length > 1) {
            Array.from(removeButtons).forEach(function (button) {
                button.classList.remove("d-none");
            });
        }
    }

    delFormat(event) {
        const delFieldset = event.target.closest("fieldset");
        const delInput = delFieldset.nextElementSibling;
        if ((delInput == null || delInput.tagName == "FIELDSET") && this.formatsTarget.childElementCount > 1) {
            delFieldset.remove();
        } else if (delInput != null && delInput.tagName == "INPUT" && this.formatsTarget.childElementCount > 2) {
            delFieldset.remove();
            delInput.remove();
            $.ajax({
                type: 'DELETE',
                dataType: 'html',
                url: `/media_formats/${delFieldset.dataset.id}`
            })
        }
        this.checkFormats()
    }
}
