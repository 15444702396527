import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["display", "slider"]
  static values = { unit: String, base: Number }

  connect(){
    this.offerController = this.application.getControllerForElementAndIdentifier(document.getElementById("offer-controller"),"offer-total");
    this.step = parseInt(this.sliderTarget.getAttribute('step'), 10);
    this.update();
  }

  update() {
    // let newValue = this.baseValue * this.sliderTarget.value /100;
    // newValue = Math.round((Number.EPSILON + Number(newValue) * 100)) /100;
    this.displayTarget.textContent = this.unitValue +  " " + this.offerController.displayTarget.textContent;
    let percentage =  (this.sliderTarget.value - this.sliderTarget.getAttribute("min")) / (this.sliderTarget.getAttribute("max") - this.sliderTarget.getAttribute("min")) * 85;
    this.displayTarget.style.left = percentage + "%";
  }

  add(){
    this.changeSliderValue(true);
  }

  subtract(){
    this.changeSliderValue(false);
  }

  changeSliderValue(do_add){
    let currentSliderValue = parseInt(this.sliderTarget.value, 10);
    let newStepValue = currentSliderValue;

    if (do_add) {
      newStepValue = currentSliderValue + this.step;
    } else {
      newStepValue = currentSliderValue - this.step;
    }

    $(this.sliderTarget).val(newStepValue);
    let event = new CustomEvent("updated");
    window.dispatchEvent(event);
    this.update();
  }

}
