import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["slider_min", "slider_max", "tooltip_min", "tooltip_max"]
  static values = { dataType: String, possibleMin: String, possibleMax: String, multiplier: Number }

  connect() {
    if (false) {
      console.log("hello from double_slider stimulus controller")
      console.log("this.slider_minTarget", this.slider_minTarget)
      console.log("this.slider_maxTarget", this.slider_maxTarget)
      console.log("this.dataTypeValue", this.dataTypeValue)
      console.log("this.possibleMinValue", this.possibleMinValue)
      console.log("this.possibleMaxValue", this.possibleMaxValue)
      console.log("this.tooltip_minTarget", this.tooltip_minTarget)
      console.log("this.tooltip_maxTarget", this.tooltip_maxTarget)
    }

    var theThis = this;

    if (this.dataTypeValue == "DateTime") {
      this.dateStart = new Date(this.possibleMinValue)
      this.dateEnd = new Date(this.possibleMaxValue)
      //console.log("It's a date range ! ", this.dateStart, "<->", this.dateEnd)
    }

    this.setTooltips()

    // setup event listeners on value change ..
    var lowerSlider = this.slider_minTarget
    var upperSlider = this.slider_maxTarget
    var lowerVal = parseFloat(lowerSlider.value)
    var upperVal = parseFloat(upperSlider.value)
    var offset = 0

    var theThis = this;

    upperSlider.oninput = function () {
      lowerVal = parseFloat(lowerSlider.value);
      upperVal = parseFloat(upperSlider.value);

      if (upperVal < lowerVal + offset) {
        lowerSlider.value = upperVal - offset;

        if (lowerVal == lowerSlider.min) {
          upperSlider.value = offset;
        }
      }
      theThis.setTooltips();

    };


    lowerSlider.oninput = function () {
      lowerVal = parseFloat(lowerSlider.value);
      upperVal = parseFloat(upperSlider.value);

      if (lowerVal > upperVal - offset) {
        upperSlider.value = lowerVal + offset;

        if (upperVal == upperSlider.max) {
          lowerSlider.value = parseFloat(upperSlider.max) - offset;
        }

      }
      theThis.setTooltips();
    };

    lowerSlider.onchange = function () { console.log("CHanged") }


  }

  setTooltips() {
    var range;
    var ratio;
    var styleString;
    var thumbSize = 28;

    // range = this.slider_minTarget
    // ratio = (range.value - range.min) / (range.max - range.min)
    // styleString = `calc(${thumbSize / 10}px + ${ratio * 100}px - ${ratio} * ${thumbSize}px)`
    // styleString = `${ratio * 100}%`
    //console.log("set left for MIN tooltip to '" + styleString + "'", $(this.tooltip_minTarget))
    //$(this.tooltip_minTarget).css("left",styleString)


    // range = this.slider_maxTarget
    // ratio = (range.value - range.min) / (range.max - range.min)
    // styleString = `calc(${thumbSize / 10}px + ${ratio * 100}px - ${ratio} * ${thumbSize}px)`
    // styleString = `${ratio * 100}%`
    //console.log("set left for MAX tooltip to '" + styleString + "'", $(this.tooltip_maxTarget))
    //$(this.tooltip_maxTarget).css("left",styleString)


    // change tooltip texts .. based on data-type (number vs date)
    var minStr = "" + this.slider_minTarget.value
    var maxStr = "" + this.slider_maxTarget.value


    // standardized values from 0 to 1
    var min_std = this.slider_minTarget.value
    var max_std = this.slider_maxTarget.value
    console.log(`Tooltips: ${this.multiplierValue} - ${min_std} - ${max_std}`)
    if (this.dataTypeValue == "DateTime") {

      // min and max defined by range option of subfilter e.g. -10 to 200, or
      var min_actual = this.dateStart
      var max_actual = this.dateEnd
      var diff_actual_in_milliseconds = max_actual.getTime() - min_actual.getTime()
      // To calculate the no. of days between two dates
      var diff_actual_in_days = diff_actual_in_milliseconds / (1000 * 3600 * 24);

      //console.log("diff in days ", diff_actual_in_days, "in ms", diff_actual_in_milliseconds)
      //var min = new Date(min_actual.getDate() + (diff_actual_in_days*min_std))
      //var max = new Date(min_actual.getDate() + (diff_actual_in_days*max_std))

      var min = new Date();
      min.setDate(min_actual.getDate() + (diff_actual_in_days * min_std))
      var max = new Date();
      max.setDate(min_actual.getDate() + (diff_actual_in_days * max_std))

      // format date
      var options = { year: '2-digit', month: 'numeric', day: 'numeric' };

      minStr = "" + min.toLocaleDateString("de-DE", options)
      maxStr = "" + max.toLocaleDateString("de-DE", options)

    } else { // assume number

      // min and max defined by range option of subfilter e.g. -10 to 200, or
      var min_actual = parseFloat(this.possibleMinValue)
      var max_actual = parseFloat(this.possibleMaxValue)
      var diff_actual = max_actual - min_actual


      //var min = min_actual + (diff_actual * min_std)
      //var max = min_actual + (diff_actual * max_std)
      //if (this.hasMultiplierValue) {
      //  min = min * this.multiplierValue
      //  max = max * this.multiplierValue
      //}
      min = parseInt(min_std)
      max = parseInt(max_std)

      if (this.dataTypeValue == "Integer") {
        min = Math.round(min)
        max = Math.round(max)
      }


      minStr = "" + this.toFixedIfNecessary(min, 2)
      maxStr = "" + this.toFixedIfNecessary(max, 2)
    }


    $(this.tooltip_minTarget).text(minStr)
    $(this.tooltip_maxTarget).text(maxStr)
  }

  toFixedIfNecessary(value, dp) {
    return +parseFloat(value).toFixed(dp);
  }

  disconnect() {
    console.log("stimulus disconnected double_slider")
  }

}
