import { Controller } from "@hotwired/stimulus"
export default class extends Controller {

  connect() {
    if($(this.element).children().length > 0){
      $(this.element).parent().addClass("validation-failed")
      let page = $(this.element).closest("[data-page-number]");
      if(page.length > 0){
        let pageNumber = page.attr("data-page-number");
        let pageIndicator = $("#page-indicator-" + pageNumber);
        let indicatorImage = pageIndicator[0].children.item(0).children.item(0);
        indicatorImage.style.display = "block";
        if(!window.firstErrorPage || window.firstErrorPage > pageNumber){
          window.firstErrorPage = pageNumber;
          let navigation = $("[data-controller=horizontal-scroll]")[0];
          let navigationController = this.application.getControllerForElementAndIdentifier(navigation, "horizontal-scroll");
          navigationController.gotoPage(pageNumber);
        }
      }
    }

  }

  disconnect(){
  }
}
