import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = [ "form", "formats", "totals", "sum" ]
  static values = {
    mfSize: Number
  }

  connect() {
    setTimeout(() => {  this.calculate(); }, 300);
  }

  add_format() {
    this.mfSizeValue += 1;
    const regex = /offer\[media_formats_attributes\]\[\d+\]/g;
    const new_id = "offer[media_formats_attributes][" + this.mfSizeValue + "]";
    let oldFieldset = this.formatsTarget.lastElementChild;
    if (oldFieldset.tagName == "INPUT" ) {
      oldFieldset = oldFieldset.previousElementSibling;
    }
    const newFieldset = oldFieldset.outerHTML.replace(regex, new_id)
    this.formatsTarget.insertAdjacentHTML("beforeend", newFieldset);
    this.calculate();
  }

  del_format(event) {
    const delFieldset = event.target.closest("fieldset");
    const delInput = delFieldset.nextElementSibling;
    if ((delInput==null || delInput.tagName=="FIELDSET") && this.formatsTarget.childElementCount>1) {
      delFieldset.remove();
      this.calculate();
    } else if (delInput!=null && delInput.tagName=="INPUT" && this.formatsTarget.childElementCount>2) {
      delFieldset.remove();
      delInput.remove();
      $.ajax({
        type: 'DELETE',
        dataType: 'html',
        url: `/media_formats/${delFieldset.dataset.id}`
      })
      this.calculate();
    }
  }

  calculate() {
    const sum = this.totalsTargets.reduce((acc, obj) => {
                  return acc + parseFloat(obj.value);
                }, 0);
    this.sumTarget.innerHTML = sum.toFixed(2);
  }

}
