import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['text', 'arrow']
    static values = {
        view: {type: String, default: "View job details"},
        roll: {type: String, default: "Roll up job details"}
    }

    connect() {
        this.toggle()
    }

    toggle() {
        let opened = this.element.ariaExpanded === 'true'
        if (opened) {
            let screen= document.getElementsByClassName('main-row')[0]
            this.textTarget.textContent = this.rollValue;
            this.arrowTarget.classList.add('chevron-up');
            this.arrowTarget.classList.remove('chevron-down');
            this.element.classList.add('btn-open');
            setTimeout(function() {
                screen.scrollTo({ top: screen.scrollHeight, behavior: 'smooth' });
            }, 200);
        } else {
            this.textTarget.textContent = this.viewValue;
            this.arrowTarget.classList.add('chevron-down');
            this.arrowTarget.classList.remove('chevron-up');
            this.element.classList.remove('btn-open');
        }
    }
}
