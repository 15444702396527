import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["parent"]

  connect() {
    this.template = this.element.querySelector("template");
    this.idCounter = -1;
  }

  add(){
    let clone = this.template.content.cloneNode(true);
    clone.children[0].innerHTML = clone.children[0].innerHTML.replaceAll("[0]", "[" + this.idCounter + "]");
    clone.children[0].innerHTML = clone.children[0].innerHTML.replaceAll("_0_", "_" + this.idCounter + "_");
    this.idCounter--;
    this.parentTarget.appendChild(clone);
    this.dispatch("element-added");
  }

  remove(event){
    let item = event.srcElement.closest("[data-zero-to-n-target=item");
    item.remove();
    this.dispatch("element-removed");

  }
}
