import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    const element = this.element;
    $(element).on('change', 'input[role=sent-switch]', function () {

      $.ajax({
        type: 'POST',
        dataType: 'script',
        url: `/shippings/${this.getAttribute('data-shipping-id')}/sent`,
        data: {
          sent: $(this).is(':checked'),
        }
      })
    })
  }
}
