import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { 
  }

  static targets = ["svg", "path"]

  initialize() {


  }

  connect() {
    console.log("roter faden connected", this.element)
    this.svgOverlay = null


    $().ready(function() { // that seems a bit ugly
      this.svgOverlay = window.svgOverlay

      console.log("roter faden ready, connect with main svgOverlay", this.svgOverlay)
      /*
      this.mainSvg = this.svgOverlay.element
      this.pathD = this.element.getAttribute("d")
      console.log("roter faden path 'd'", this.pathD
      
      // TODO: create points
      this.points = []

      this.resizeSvg()

      this.noiseStep = 0.005

      this.map = function map(n, start1, end1, start2, end2) {
        return ((n - start1) / (end1 - start1)) * (end2 - start2) + start2
      }

      var simplex = new SimplexNoise()
      this.noise = function noise(x, y) {
        return simplex.noise2D(x, y);
      }

      // console.log("points", this.points)
      this.animate()
      */
    })

  }

  disconnect() {
    console.log("roter faden disconnect")
  }

  animate() {
		// console.log("this",this)
		// console.log("points", this.points)
		var points = this.points
		var splineAsPathStr = spline(points,1,true)
		// console.log("splineAsPathStr", splineAsPathStr)
		this.pathTarget.setAttribute("d",splineAsPathStr)

		// for every point...
		for (let i = 0; i < points.length; i++) {
			const point = points[i];

			// return a pseudo random value between -1 / 1 based on this point's current x, y positions in "time"
			const nX = this.noise(point.noiseOffsetX, point.noiseOffsetX);
			const nY = this.noise(point.noiseOffsetY, point.noiseOffsetY);
			// map this noise value to a new value, somewhere between it's original location -20 and it's original location + 20
			var amount = 5
			const x = this.map(nX, -1, 1, point.originX - amount, point.originX + amount);
			const y = this.map(nY, -1, 1, point.originY - amount, point.originY + amount);

			// update the point's current coordinates
			point.x = x;
			point.y = y;

			// progress the point's x, y values through "time"
			point.noiseOffsetX += this.noiseStep;
			point.noiseOffsetY += this.noiseStep;
		}

		/*
		const hueNoise = noise(hueNoiseOffset, hueNoiseOffset);
		const hue = map(hueNoise, -1, 1, 0, 360);

		root.style.setProperty("--startColor", `hsl(${hue}, 100%, 75%)`);
		root.style.setProperty("--stopColor", `hsl(${hue + 60}, 100%, 75%)`);
		document.body.style.background = `hsl(${hue + 60}, 75%, 5%)`;

		hueNoiseOffset += noiseStep / 6;
		*/

		var stThis = this
		var animFunc = function() {
			stThis.animate()
		}
		requestAnimationFrame(animFunc)
  }


  resizeSvg() {


    // var bbox = this.svgTarget.getBBox()
    var bbox = {width: this.element.offsetWidth, height: this.element.offsetHeight}
    console.log("resize svg to", bbox)
    this.svgTarget.setAttribute("width", bbox.width + "px")
    this.svgTarget.setAttribute("height", bbox.height + "px")
    this.svgTarget.setAttribute("viewBox", `0 0 ${bbox.width} ${bbox.height}`)
    // this.svgTarget.setAttribute("viewBox", `${bbox.x} ${bbox.y} ${bbox.width} ${bbox.height}`)
    //
    var min = 0.1 // 0
    var max = 0.9 // 1
    var mid = (min + max) / 2
    var offset = 0.05

    var width = bbox.width
    var height = bbox.height
    var wMapd = width*(max-min)
    var hMapd = height*(max-min)
    

    var testMul = 1
    this.pathRelPoints = [
      {x: min, y: min, originX: min, originY: min},
      {x: mid, y: min-offset, originX: mid, originY: min-offset},

      {x: max, y: min, originX: max, originY: min},
      // {x: max, y: mid, originX: max, originY: mid},

      {x: max, y: max, originX: max, originY: max},
      {x: mid, y: max+offset, originX: mid, originY: max+offset},

      {x: min, y: max, originX: min, originY: max},
      // {x: min, y: mid, originX: min, originY: mid},
    ]

    this.points = []

    this.pathRelPoints.forEach( (pPoint, index) => {
      this.points.push({
        x: width*min + pPoint.x*wMapd, 
        y: height*min + pPoint.y*hMapd,
        originX: width*min + pPoint.x*wMapd, 
        originY: height*min + pPoint.y*hMapd,
				noiseOffsetX: Math.random() * 1000,
				noiseOffsetY: Math.random() * 1000
      })
    })


  }


}
