import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["textUrl", "sendBtn", "preview", "insta", "hiddenWarning"]

  connect() {
    const instaBlock = this.hasInstaTarget ? this.instaTarget : null;
    $(instaBlock).hide();
    $(this.hiddenWarningTarget).hide();
    if (window.instgrm) {
      window.instgrm.Embeds.process();
      // hide long line of empty initial iframe (occurs because of empty insta href)
      setTimeout(this.initPreview.bind(this), 500)
    } else {
      $.ajax({
        url: "//www.instagram.com/embed.js",
        dataType: 'script',
        success: () => {
          setTimeout(this.initPreview.bind(this), 500)
        },
        async: true
      });
    }
    const $this = this;
    $(".media-grid-ele", this.element).on('click', function () {
      var embed_url = $(this).attr("data-embed-link");
      var hidden_post = $(this).attr("data-hidden-post") === 'true'
      $this.textUrlTarget.value = embed_url;
      $this.updateResult($this.textUrlTarget, false, hidden_post)
    });

    // Firefox specific behavior
    if(navigator.userAgent.toLowerCase().indexOf('firefox') > -1){
      $(".media-grid-ele", this.element).each(function (index) {
        var mediaEle = $(this);
        mediaEle[0].addEventListener('play', function () {
          var embed_url = $(this).attr("data-embed-link");
          $this.textUrlTarget.value = embed_url;
          $this.updateResult($this.textUrlTarget)
        }, true);
      });
    }
  }

  initPreview() {
    const instaBlock = this.hasInstaTarget ? this.instaTarget : null;
    $(instaBlock).hide();
    $("iframe", instaBlock).css("border", "none");
    this.updateResult(this.textUrlTarget, true);
  }

  onUrlUpdate(event) {
    this.updateResult(event.target)
  }

  // border: 1px solid rgb(219, 219, 219)

  instaResult(url) {
    var result = url.split("/")[4];
    var embed = $(".instagram-media", this.element)[0];
    if (embed && (embed.tagName == 'IFRAME')) {
      var splitString = embed.src.split("/");
      var newLink = "";
      for (var i = 0; i < splitString.length; i++) {
        if (i == 4) {
          newLink += result;
        } else {
          newLink += splitString[i];
        }
        if (i < splitString.length - 1) {
          newLink += "/";
        }
      }
      embed.src = newLink;
    }

  }

  fbResult(url) {
    if (!(url.match(/www.facebook.com\/.*\/posts/) ||
      url.match(/www.facebook.com\/permalink\.php/) ||
      url.match(/www.facebook.com\/.*multi_permalinks/))
    ) {
      return;
    }
    const width = 500;
    const urlObj = new URL(`https://www.facebook.com/plugins/post.php?show_text=true&width=${width}`);
    urlObj.searchParams.append("href", url);
    const iframeUrl = urlObj.toString();

    this.fbIframe = $('<iframe src="' + iframeUrl + '" width="' + width + '" height="633" style="border: 1px solid rgb(219, 219, 219); border-radius: 3px; overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>').appendTo(this.previewTarget);
  }


  updateResult(input, is_first_update = false, hidden_post = false) {
    var url = input.value;
    $(this.instaTarget).hide();
    $(this.hiddenWarningTarget).hide();
    if (this.fbIframe) { this.fbIframe.remove(); this.fbIframe = null; }
    if (url.includes("https://www.instagram.com/p") || url.includes("https://www.instagram.com/reel") || url.includes("https://www.instagram.com/tv")) {
      if (hidden_post) {
        $(this.hiddenWarningTarget).show();
        if (this.hasSendBtnTarget && !is_first_update) {this.sendBtnTarget.disabled = true}
      } else {
        $(this.instaTarget).show();
        this.instaResult(url);
      }
    } else if (url.includes("https://www.facebook.com/")) {
      this.fbResult(url);
    } else if (url.length && !(url.includes("instagram.com/") || url.includes("facebook.com/"))) {
      input.value = "";
      alert("wrong url");
    }
    if (!hidden_post) {
      if (this.hasSendBtnTarget && !is_first_update) {
        this.sendBtnTarget.disabled = input.value == "";
      }

      var gridElement = $("[data-embed-link='" + url + "']");
      if (gridElement) {
        var mediaId = gridElement.attr("data-id");
        $("#media-id-field").val(mediaId);
        var permalink = gridElement.attr("data-media-url");
        $("#media-url-field").val(permalink);
      } else {
        $("#media-id-field").val("");
        $("#media-url-field").val("")
      }
    }
  }
}
