import { Controller } from "@hotwired/stimulus"
export default class extends Controller {

  connect() {
    this.spinnerOverlay = document.getElementById("spinnerOverlay")
    var spinner = this.spinnerOverlay
    this.element.addEventListener("ajax:complete", () => {
      console.log("ajax success")
      spinner.style.display = "none";
    });
  }
  onSubmit(){

    this.spinnerOverlay.style.display = "block";
  }

  disconnect(){
  }
}
