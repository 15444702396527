import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["fileField", "imageField", "videoField"]
  static values = { formatId: Number }

  connect() {
  }

  remove() {
    this.fileFieldTarget.value = null;
    if(this.hasVideoFieldTarget){
      this.videoFieldTarget.removeAttribute("src");
      this.videoFieldTarget.classList.add("hidden");
    }
    this.imageFieldTarget.removeAttribute("src");
    this.imageFieldTarget.classList.add("hidden");
    if (this.formatIdValue != 0) {
      const prevElement = this.fileFieldTarget.previousElementSibling
      if (prevElement.tagName == "INPUT") {
        prevElement.remove();
      }
      $.ajax({
        type: 'get',
        dataType: 'html',
        url: `/media_formats/${this.formatIdValue}/remove_example`
      });
    }
  }

}