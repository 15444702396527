import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    let selector = document.getElementById('timeframe');
    this.showHideStrings(selector.value);
  }

  showTimeframes(e) {
    this.showHideStrings(e.target.value);
  }

  showHideStrings(select_value) {
    switch (select_value) {
    case "30":
      $('.day_all').hide();
      $('.day_90').hide();
      $('.day_30').show();
      break;
    case "90":
      $('.day_all').hide();
      $('.day_90').show();
      $('.day_30').hide();
      break;
    case "all":
      $('.day_all').show();
      $('.day_90').hide();
      $('.day_30').hide();
      break;
    }
  }

}