import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { src: String }

  connect() {
    var src = this.srcValue;
    this.frame = this.element.querySelector("turbo-frame");
    var theThis = this;
    this.element.addEventListener('show.bs.collapse', function () {
      if(theThis.frame.getAttribute("src") != theThis.srcValue){
        theThis.frame.setAttribute("src", theThis.srcValue);
      }
    })
  }
}
