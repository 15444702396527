import { convertRemToPixels, convertPixelsToRem, lerp } from '../packs/helpers';
import { Controller } from "@hotwired/stimulus"
export default class extends Controller {

  static targets = [ "scrollarea", "row", "pageindicator", "indicatorparent","navigateleft", "navigateright", "rowcontainer"]
  static values = {currentimg: String, futureimg: String, pastimg: String}

  connect() {
    //console.log(convertRemToPixels(16))
    this.currentPage = 0
    this.tabPixelWidth = convertRemToPixels(16)
    this.totalPages = this.rowTarget.children.length
    for(var i = 1; i < this.totalPages; i++){
      let newNode = this.pageindicatorTarget.cloneNode(true);
      newNode.id = "page-indicator-" + i;
      this.indicatorparentTarget.appendChild(newNode);
    }
    var theThis = this
    for(var i = 0; i < this.totalPages; i++){
      var image = this.indicatorparentTarget.children.item(i).children.item(0)
      let pageNumber = i
      image.onclick = function() { theThis.gotoPage(pageNumber) }
    }
    this.rowcontainerTarget.style.width = 16 * this.totalPages + "rem"
    this.rowcontainerTarget.style.maxwidth = 16 * this.totalPages + "rem"
    this.rowTarget.style.width = 16 * this.totalPages + "rem"
    this.rowTarget.style.maxwidth = 16 * this.totalPages + "rem"
    this.updateNavigation();
  }

  gotoPage(pageNumber){
    this.currentPage = pageNumber
    this.scrollOnNavigationClick()
  }

  updateNavigation(){
    this.setPageIndicator();
    if(this.currentPage == 0){
      this.navigateleftTarget.hidden = true
    }
    else{
      this.navigateleftTarget.hidden = false
    }
    if(this.currentPage == this.totalPages - 1){
      this.navigaterightTarget.hidden = true
    }
    else{
      this.navigaterightTarget.hidden = false
    }
  }

  setPageIndicator(){
    for(var i = 0; i < this.totalPages; i++){
      var indicator = this.indicatorparentTarget.children.item(i)
      var indicatorImage = indicator.children.item(0).children.item(1);
      if(i > this.currentPage){
        indicatorImage.src = this.futureimgValue
      }
      else if(i < this.currentPage){
        indicatorImage.src = this.pastimgValue
      }
      else{
        indicatorImage.src = this.currentimgValue
      }
    }
  }

  // onTouchEnd(){
  //   var scrolledInTab = this.scrollareaTarget.scrollLeft % this.tabPixelWidth
  //   var target;
  //   var element = this.scrollareaTarget;
  //   //console.log("Snapping");
  //   if(scrolledInTab > this.tabPixelWidth / 2){
  //     target = this.scrollareaTarget.scrollLeft - scrolledInTab + this.tabPixelWidth
  //     console.log("Switch right")
  //   }
  //   else{
  //     target = this.scrollareaTarget.scrollLeft - scrolledInTab
  //     if(this.currentPage * this.tabPixelWidth > target){
  //       console.log("Switch left")
  //     }
  //     else{
  //       console.log("Snap back")
  //
  //     }
  //   }
  //   this.currentPage = Math.round(target / this.tabPixelWidth)
  //   this.updateNavigation();
  //
  //   element.scrollLeft = target
  //   console.log("currentpage", this.currentPage)
  //   element.style.overflowX = "hidden"
  //   var intervalID = setInterval(function()
  //   {
  //    element.scrollLeft = lerp(element.scrollLeft, target, 0.02)
  //    if(Math.abs(element.scrollLeft - target) < 10){
  //      element.scrollLeft = target
  //      clearInterval(intervalID)
  //    }
  //     //console.log(element.scrollLeft)
  //   }, 20);
  //   this.intervalID = intervalID
  // }

  scrollOnNavigationClick(){
    this.scrollareaTarget.scrollLeft = this.tabPixelWidth * this.currentPage
    this.updateNavigation()
  }

  onNavigateToRightClicked(){
    this.currentPage++
    this.scrollOnNavigationClick()
  }

  onNavigateToLeftClicked(){
    this.currentPage--
    this.scrollOnNavigationClick()
  }

  onTouchStart(){
    //this.scrollareaTarget.style.overflowX = "auto"
    if(this.intervalID){
     clearInterval(this.intervalID)
    }
  }
}
