function loadFile(event, element) {
	const imgFileTypes = ["image/jpeg", "image/png", "image/gif"]
	const videoFileTypes = ["video/mp4", "video/ogg", "video/m4v", "video/quicktime"]
	var image = $(element).siblings("img");
	var video = $(element).siblings("video");
	if (imgFileTypes.includes(event.target.files[0].type)) {
		image[0].setAttribute("src", URL.createObjectURL(event.target.files[0]));
		image[0].style.display = "block";
		video[0].style.display = "none";
	}
	if (videoFileTypes.includes(event.target.files[0].type)) {
		video[0].setAttribute("src", URL.createObjectURL(event.target.files[0]));
		image[0].style.display = "none";
		video[0].style.display = "block";
	}
};
window.loadFile = loadFile

window.loadFileNew = function (event, element) {
	$('.icon-camera').hide();
	$('.profile__no-image-icon').hide();
	$('.profile__no-image-label').hide();

	if (!event.target.files || !event.target.files.length) { return; }
	const imgFileTypes = ["image/jpeg", "image/png", "image/gif"]
	const videoFileTypes = ["video/mp4", "video/ogg", "video/m4v", "video/quicktime"]
	var container = $(element).closest("[role=file-upload-container]")
	var target = $('[role="file-upload-target"]', container)[0];
	var objectUrl = URL.createObjectURL(event.target.files[0])
	if (imgFileTypes.includes(event.target.files[0].type)) {
		target.style.backgroundImage = `url(${objectUrl})`;
	}
	/*if (videoFileTypes.includes(event.target.files[0].type)) {
		video[0].setAttribute("src", URL.createObjectURL(event.target.files[0]));
		image[0].style.display = "none";
		video[0].style.display = "block";
	}*/
};
