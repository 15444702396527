import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { breakpoint: Number, listIsShowing: Boolean }
  static targets = ["list", "detail", "activeItem"]

  connect() {
    this.hideOnOverlay = $(".hide-on-mobile-detail-overlay");
    this.showOnOverlay = $(".show-on-mobile-detail-overlay");
    this.listIsShowing = false;
    var theThis = this;
    this.resizeEventId = window.addEventListener("resize", function () {
      theThis.onResize();
    });
    this.backButton = document.getElementById("backButton");
    if (this.hasListTarget){
      this.backClickEventId = this.backButton.addEventListener("click", function () {
        theThis.backClicked();
      });
    }
    this.onResize();
  }

  onResize() {
    if (window.innerWidth > this.breakpointValue) {
      if (this.hasListTarget){
        this.backButton.classList.add("hidden");
        this.listTarget.classList.remove("hidden");
      }
      if (this.hasDetailTarget){
        this.detailTarget.classList.remove("hidden");
      }
      this.showOnOverlay.addClass("hidden");
      this.hideOnOverlay.removeClass("hidden");
      if (this.hasActiveItemTarget) { this.activeItemTarget.classList.add("active"); }
    }
    else if (this.listIsShowingValue) {
      this.listIsShowingValue = false;
      if (this.hasListTarget){
        this.backButton.classList.add("hidden");
        this.listTarget.classList.remove("hidden");
      }
      if (this.hasDetailTarget){
        this.detailTarget.classList.add("hidden");
      }
      this.showOnOverlay.addClass("hidden");
      this.hideOnOverlay.removeClass("hidden");
      if (this.hasActiveItemTarget) { this.activeItemTarget.classList.remove("active"); }
    }
    else {
      if (this.hasListTarget){
        this.backButton.classList.remove("hidden");
        this.listTarget.classList.add("hidden");
      }
      this.hideOnOverlay.addClass("hidden");
      if (this.hasDetailTarget){
        this.detailTarget.classList.remove("hidden");
      }
      this.showOnOverlay.removeClass("hidden");
      if (this.hasActiveItemTarget) { this.activeItemTarget.classList.remove("active"); }
    }
  }

  backClicked() {
    if (window.innerWidth < this.breakpointValue) {
      if (this.hasListTarget){
        this.listTarget.classList.remove("hidden");
        this.backButton.classList.add("hidden");
      }
      if (this.hasDetailTarget){
        this.detailTarget.classList.add("hidden");
      }
      this.hideOnOverlay.removeClass("hidden");
    }
    else {
      if (this.hasListTarget){
        this.listTarget.classList.remove("hidden");
        this.detailTarget.classList.remove("hidden");
      }
      this.hideOnOverlay.removeClass("hidden");
    }
    this.showOnOverlay.addClass("hidden");

    this.listIsShowing = true;
  }

  disconnect() {
    if (this.hasListTarget){
      this.backButton.removeEventListener("click", this.backClickEventId);
    }
    window.removeEventListener("resize", this.resizeEventId);
  }
}
