import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { target: String }
  connect(){
    this.target = document.getElementById(this.targetValue);
    if(this.element.value != ""){
      this.update();
    }
  }

  update(){
    if (this.target){
      this.target.innerHTML = this.element.value;
    }
  }
}
