import {Controller} from "stimulus"

export default class extends Controller {

    connect() {
        this.adjustHeight();
        const modal = document.getElementById("newJobModal")

        modal.addEventListener("shown.bs.modal", () => {
            this.adjustHeight();
        })

        window.addEventListener("resize", () => {
            this.adjustHeight();
        });
    }

    adjustHeight() {
        const elements = this.element.querySelectorAll(".equal-height-block");
        let maxHeight = 60;

        elements.forEach((element) => {
            element.style.minHeight = "auto";
            const height = element.offsetHeight;
            if (height > maxHeight) {
                maxHeight = height;
            }
        });

        elements.forEach((element) => {
            element.style.minHeight = maxHeight + "px";
        });

    }
}


