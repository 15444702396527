import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["hide", "show"]
  static values = { hideIds: String, showIds: String }

  trigger(){
    for(var i = 0; i < this.hideTargets.length; i++){
      $(this.hideTargets[i]).hide();
    }
    $(this.hideIdsValue).hide();
    for(var i = 0; i < this.showTargets.length; i++){
      $(this.showTargets[i]).show();
    }
    $(this.showIdsValue).show();
  }
}
