import { Controller } from "@hotwired/stimulus"
import * as bootstrap from 'bootstrap'

export default class extends Controller {
  static values = { collapseId: String, class: String, hideTargets: String }

  connect() {
    if(this.hasHideTargetsValue && this.hideTargetsValue != ''){
      this.hideCollapses = [];
      let split = this.hideTargetsValue.split(",");
      split.forEach(target => this.hideCollapses.push(new bootstrap.Collapse(document.getElementById(target), {toggle: false})));
    }
    this.myCollapsible = document.getElementById(this.collapseIdValue)

    var theThis = this;
    this.hideListener = this.myCollapsible.addEventListener('hide.bs.collapse', function (event) {
      if(event.target == theThis.myCollapsible){
        theThis.element.classList.remove(theThis.classValue);
      }
    })
    this.showListener =  this.myCollapsible.addEventListener('show.bs.collapse', function (event) {
      if(event.target == theThis.myCollapsible){
        theThis.element.classList.add(theThis.classValue);
        document.cookie = "active_collapse = " + theThis.collapseIdValue
        if(theThis.hasHideTargetsValue && theThis.hideTargetsValue != ''){
          theThis.hideCollapses.forEach(collapse => collapse.hide());
        }
      }
    })
  }

  disconnect(){
    if(this.myCollapsible){
      this.myCollapsible.removeEventListener('hide.bs.collapse',this.hideListener);
      this.myCollapsible.removeEventListener('show.bs.collapse',this.showListener);
    }
  }
}
