import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["product", "input", "value", "description", "link", "code"]

  connect() {
    if (!this.productTarget.checked) {
      this.hideInput()
    }
  }

  change() {
    if (this.productTarget.checked) {
      this.showInput()
    } else {
      this.hideInput()
      this.valueTarget.value = '0.0'
      this.descriptionTarget.value = ''
      this.linkTarget.value = ''
      this.codeTarget.value = ''
    }
  }

  hideInput() {
    this.inputTarget.style.display = 'none'
  }

  showInput() {
    this.inputTarget.style.display = ''
  }
}