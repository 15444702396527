// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
//Turbo.session.drive = false
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import '@hotwired/turbo-rails'

import JQuery from 'jquery'
window.$ = window.JQuery = JQuery

import { spline } from '@georgedoescode/spline'
window.spline = spline
import SimplexNoise from 'simplex-noise'
window.SimplexNoise = SimplexNoise


import VanillaTilt from 'vanilla-tilt'
window.VanillaTilt = VanillaTilt

import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

let env = window.location.hostname;
if (env.includes("unuh.ch")) {
    env = env.split('.')[0]
    env = env == 'marketplace' ? 'production' : 'staging'

    Sentry.init({
        dsn: "https://77c638b30f2b4553b6d4abf015bceb1a@o1366048.ingest.sentry.io/6662694",
        integrations: [new BrowserTracing()],
        environment: env,

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.1,
    });

    $(function () {
        Sentry.setUser({ id: $('#logout_button').data('userId') });
    })

}

/* Patched version of handler for @hotwired/turbo-rails:
*   Originally it doesn't turned off on the links with [data-method] even with [data-turbo="false"].
*   The reason of that in rails/ujs that generates a new form that doesn't respect that attr.
*   The whole function is copy-past from rails-ujs.js except of commented part.
*/
Rails.delegate(document, Rails.linkClickSelector, 'click', function (e) {
    var csrfParam, csrfToken, form, formContent, href, link, method;
    link = this;
    method = link.getAttribute('data-method');
    if (!method) {
        return;
    }
    href = Rails.href(link);
    csrfToken = Rails.csrfToken();
    csrfParam = Rails.csrfParam();
    form = document.createElement('form');
    formContent = "<input name='_method' value='" + method + "' type='hidden' />";
    if ((csrfParam != null) && (csrfToken != null) && !Rails.isCrossDomain(href)) {
        formContent += "<input name='" + csrfParam + "' value='" + csrfToken + "' type='hidden' />";
    }
    formContent += '<input type="submit" />';
    form.method = 'post';
    /* -- Patch for turbo -- */
    if (link.getAttribute('data-turbo') == "false") {
        form.setAttribute('data-turbo', "false");
    }
    /* --------------------- */
    form.action = href;
    form.target = link.target;
    form.innerHTML = formContent;
    form.style.display = 'none';
    document.body.appendChild(form);
    form.querySelector('[type="submit"]').click();
    return Rails.stopEverything(e);
});

$(document).on("turbo:load", function () {
    $.rails.refreshCSRFTokens();
});

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require("trix")
require("@rails/actiontext")

import * as bootstrap from 'bootstrap'
window.bootstrap = require("bootstrap")
import "../stylesheets/application"
import Sortable from 'sortablejs'
window.Sortable = Sortable
import anime from 'animejs'
window.anime = anime

window.openBsModal = function (modalId, elementHtml, options) {
    var prevElement = document.getElementById(modalId);
    if (prevElement) {
        prevElement.remove();
    }
    document.body.innerHTML += elementHtml;
    var newElement = document.getElementById(modalId)
    var popup = new bootstrap.Modal(newElement, options);
    popup.show();

    return popup;
}

window.openBsModalWithoutDestroy = function (modalId, options) {
    var newElement = document.getElementById(modalId)
    var popup = new bootstrap.Modal(newElement, options);
    popup.show();

    return popup;
}

window.removeBsModal = function (modalId) {
    var element = document.getElementById(modalId);
    if (!element) { return; }

    bootstrap.Modal.getInstance(element).dispose();
    element.remove();
}

window.galleryMainWindowReplace = function (newId, html, path) {
    var jobsList = $(".jobs-list");
    if (jobsList.length == 0) {
        location.href = path;
        return;
    }
    var currentActive = $(".mb-1.active", jobsList);
    var newActive = $(`.mb-1[data-id=${newId}]`, jobsList);
    if (newActive.length) {
        currentActive.removeClass("active").removeAttr("data-mobile-detail-overlay-target");
        newActive.addClass("active").attr("data-mobile-detail-overlay-target", "activeItem");
        $(".app__body").html(html).removeClass("hidden");
        // ! triggers reconnect on MobileDetailOverlay
        $(".row.main-row").removeAttr("data-controller");
        setTimeout(function () {
            $(".row.main-row").attr("data-controller", "mobile-detail-overlay");
        }, 100);
        window.history.pushState({}, "Unuh", path);
    }
}

window.icoTogglePassword = function (elementInput, elementIco) {
    let password = document.querySelector(elementInput);
    let togglePassword = document.querySelector(elementIco);

    const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
    password.setAttribute('type', type);

    togglePassword.classList.toggle('icon-job-eye');
    togglePassword.classList.toggle('icon-actions-eye-off');
}

window.addEventListener("direct-upload:initialize", event => {
    const { target, detail } = event
    const { id, file } = detail
    console.log("direct-upload:initialize: ", detail)
    /*target.insertAdjacentHTML("beforebegin", `
      <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
        <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
        <span class="direct-upload__filename"></span>
      </div>
    `)
    target.previousElementSibling.querySelector(`.direct-upload__filename`).textContent = file.name
    */
})

window.addEventListener("direct-upload:start", event => {
    const { id } = event.detail
    console.log("direct-upload:start: ", event.detail)
    //const element = document.getElementById(`direct-upload-${id}`)
    //element.classList.remove("direct-upload--pending")
})

window.addEventListener("direct-upload:progress", event => {
    const { id, progress } = event.detail
    console.log("direct-upload:progress: ", event.detail)
    //const progressElement = document.getElementById(`direct-upload-progress-${id}`)
    //progressElement.style.width = `${progress}%`
})

window.addEventListener("direct-upload:error", event => {
    event.preventDefault()
    const { id, error } = event.detail
    console.log("direct-upload:error: ", event.detail)
    //const element = document.getElementById(`direct-upload-${id}`)
    //element.classList.add("direct-upload--error")
    //element.setAttribute("title", error)
})

window.addEventListener("direct-upload:end", event => {
    const { id } = event.detail
    console.log("direct-upload:end: ", event.detail)
    //const element = document.getElementById(`direct-upload-${id}`)
    //element.classList.add("direct-upload--complete")
})

require('activeadmin_froala_editor')

// import './swipehandler'
// import './autoReload'
// import './dashboardcolumns'
import './validation'
import './fileUpload'
import './popOver'

// import './notificationHandler'
//

import 'controllers' // stimulus controllers
import './add-eu-banner'
import './shepherd'
import './editProfile'
import Plyr from 'plyr';
window.Plyr = Plyr;
import "chartkick/chart.js"
import Circles from 'circles.js'
window.Circles = Circles
import './facebook'
