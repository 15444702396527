import { Controller } from "@hotwired/stimulus"

// DEPRECATED unless we improve UI on results
export default class extends Controller {
  static targets = ["goodRatingButton", "averageRatingButton", "rejectionForm", "badRatingButton"]
  static values = { resultId: String, wasRated: Boolean }

  connect() {
    if (!this.hasGoodRatingButtonTarget) {
      // no result yet
      return;
    }
    var consumer = this.element.closest("[data-controller=aggregated-results]");
    var controller = this.application.getControllerForElementAndIdentifier(consumer, "aggregated-results");
    this.aggregateController = controller;
    if (this.wasRatedValue && this.aggregateController) {
      this.aggregateController.resultRated();
    }
    var theThis = this;
    $(this.goodRatingButtonTarget).on('click', { rating: "good", controller: this }, this.rateAsync);
    $(this.averageRatingButtonTarget).on('click', { rating: "neutral", controller: this }, this.rateAsync);
    //$(this.badRatingButtonTarget).on('click', { rating: "bad", controller: this }, this.rateAsync);
    //$(this.badRatingButtonTarget).click(function(){ theThis.updateButtons("none")});

    var rejectionForm = this.rejectionFormTarget;
    if (rejectionForm) {
      rejectionForm.addEventListener("ajax:success", (event, xhr) => {
        if (event.detail[0].state == "ok") {
          event.data = {};
          event.data.controller = theThis;
          event.data.rating = "bad";
          theThis.processResponse(event);
        }
      });
      rejectionForm.addEventListener("ajax:error", () => {

      });
    }
  }

  // DEPRECATED: not used since the request is sync now
  rateAsync(event) {
    console.log("rateAsync", event);
    $.ajax({
      method: "POST",
      url: "/rate_job_result",
      data: { "id": event.data.controller.resultIdValue, "rating": event.data.rating, "authenticity_token": $('[name="csrf-token"]')[0].content },
      success: function (data) {
        if (data.state == "ok") {
          event.data.controller.processResponse(event);
        }
      }
    })
  }

  processResponse(event) {
    if (!event.data.controller.wasRatedValue) {
      if (event.data.controller.aggregateController) {
        event.data.controller.aggregateController.resultRated();
      }
      event.data.controller.wasRatedValue = true;
    }
    event.data.controller.updateButtons(event.data.rating);
  }

  updateButtons(rating) {
    $(this.goodRatingButtonTarget).removeClass('disabled');
    $(this.badRatingButtonTarget).removeClass('disabled');
    $(this.averageRatingButtonTarget).removeClass('disabled');
    switch (rating) {
      case "good":
        $(this.goodRatingButtonTarget).addClass('disabled');
        break;
      case "bad":
        $(this.badRatingButtonTarget).addClass('disabled');
        break;
      case "neutral":
        $(this.averageRatingButtonTarget).addClass('disabled');
        break;
      case "none":
        break;
    }
  }

  // TODO: remove deprecated
  updateButtonsOld(rating) {
    var unselectedColor = "var(--color-unuh-green)";
    switch (rating) {
      case "good":
        $(this.goodRatingButtonTarget).css("background-color", "var(--green)");
        $(this.badRatingButtonTarget).css("background-color", unselectedColor);
        $(this.averageRatingButtonTarget).css("background-color", unselectedColor);
        break;
      case "bad":
        $(this.badRatingButtonTarget).css("background-color", "var(--red)");
        $(this.goodRatingButtonTarget).css("background-color", unselectedColor);
        $(this.averageRatingButtonTarget).css("background-color", unselectedColor);
        break;
      case "neutral":
        $(this.averageRatingButtonTarget).css("background-color", "var(--yellow)");
        $(this.goodRatingButtonTarget).css("background-color", unselectedColor);
        $(this.badRatingButtonTarget).css("background-color", unselectedColor);
        break;
      case "none":
        $(this.averageRatingButtonTarget).css("background-color", unselectedColor);
        $(this.goodRatingButtonTarget).css("background-color", unselectedColor);
        $(this.badRatingButtonTarget).css("background-color", unselectedColor);
        break;
    }
  }

}
