import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ["tabs"];
    static values = {
        social: { type: String, default: 'instagram' },
        job: { type: Number, default: 0 },
        task: { type: Number, default: 0}
    }

    connect() {
        this.showTab(this.socialValue);
    }

    showTab(social) {
        const url = `/job_description/post_types?social_channel=${social}&job_id=${this.jobValue}&job_description_id=${this.taskValue}`;
        $.ajax({
            type: 'GET',
            dataType: 'script',
            url: url,
        })
    }

    selectTab(event) {
        let social = event.currentTarget.dataset.social;
        this.showTab(social)
        this.tabsTargets.forEach(tab => tab.classList.remove('active'));
        event.currentTarget.classList.add('active');
    }
}
