import { Controller } from "@hotwired/stimulus"
const imgFileTypes = ["image/jpeg", "image/png", "image/gif"]
const videoFileTypes = ["video/mp4", "video/ogg", "video/m4v", "video/quicktime"]
export default class extends Controller {

  static targets = ["fileField", "removeButton", "videoField", "imageField" ]
  static values  = { maxSize: Number }

  changed(event) {
    if(event.target.files.length > 0){
      if (this.maxSizeValue > 0 && this.checkMaxFileSize(event.target.files[0])) {
        this.fileFieldTarget.value = null;
        return false;
      }
      if(this.hasRemoveButtonTarget){ this.removeButtonTarget.classList.remove("hidden");}
      if(imgFileTypes.includes(event.target.files[0].type)){
        this.imageFieldTarget.setAttribute("src", URL.createObjectURL(event.target.files[0]));
        this.imageFieldTarget.classList.remove("hidden");
        if(this.hasVideoFieldTarget){
          this.videoFieldTarget.classList.add("hidden");
        }
      }
      if(videoFileTypes.includes(event.target.files[0].type)){
        if(this.hasVideoFieldTarget){
          this.videoFieldTarget.setAttribute("src", URL.createObjectURL(event.target.files[0])+'#t=0.01');
          this.videoFieldTarget.classList.remove("hidden");;
        }
        this.imageFieldTarget.classList.add("hidden");
      }
    }
  }

  changed_submit(event) {
    this.changed(event);
    document.getElementById('switch_params').setAttribute('name','switch_image');
    document.getElementById('hidden_submit').click();
  }

  remove(event){
    this.resetSizeError();
    if(this.hasRemoveButtonTarget){ this.removeButtonTarget.classList.add("hidden");}
    this.fileFieldTarget.value = null;
    if(this.hasVideoFieldTarget){
      this.videoFieldTarget.removeAttribute("src");
      this.videoFieldTarget.classList.add("hidden");
    }
    this.imageFieldTarget.removeAttribute("src");
    this.imageFieldTarget.classList.add("hidden");
  }

  checkMaxFileSize(file) {
    let alertElement = document.getElementById('size-error')
    if (file.size > this.maxSizeValue) {
      alertElement.style.display = "block";
      return true;
    } else {
      alertElement.style.display = "none";
      return false;
    }
  }

  resetSizeError() {
    if (this.maxSizeValue > 0) {
      document.getElementById('size-error').style.display = "none";
    }
  }
}
