import {Controller} from 'stimulus';

export default class extends Controller {

    connect() {
        let id = this.data.get("id")
        let hashRow = document.getElementById(`hashtag_form${id}`);
        this.wordArray = hashRow.value ? hashRow.value.split(',') : [];
        id ? this.preview(id) : null;
        this.show(id)
    }

    back() {
        let id = this.data.get("id")
        let input = document.getElementById("hashtag-input");
        if (input.value.length === 0 && event.keyCode === 8) {
            this.wordArray.pop()
        }
        let hashtagForm = document.getElementById(`hashtag_form${id}`);
        hashtagForm.value = this.wordArray.join(', ') || '';
        id ? this.preview(id) : null;
        this.show(id)
    }

    wrap() {
        let id = this.data.get("id")
        let input = document.getElementById("hashtag-input");
        if (event.data && (event.data.includes(',') || event.data.includes(' ')) && input.value.length > 0) {
            var hashtag = input.value.trim();
            hashtag = hashtag.replace(/[\s.,;#%]/g, '');
            if (hashtag.length > 0) this.wordArray.push(hashtag);
            input.placeholder = '';
            input.value = '';
        } else {
            this.lastHashtag = input.value.replace(/[\s.,%]/g, '')
        }
        let hashtagForm = document.getElementById(`hashtag_form${id}`);
        hashtagForm.value = this.wordArray.join(', ') || '';
        hashtagForm.value += (this.lastHashtag !== '') && this.wordArray.length > 0 ? `, ${this.lastHashtag}` : this.lastHashtag
        this.lastHashtag = ''
        id ? this.preview(id) : null
        this.show(id)
    }

    show(id) {
        let target = document.getElementById(`hashtag_presenter${id}`)
        if (!target) {return}
        let content = this.wordArray.map((word, index) =>
            `<div id=${index} class="hashtag_word">
                <div>#</div>
                <div>${word}</div>
            </div>`
        ).join('')
        target.querySelectorAll(".hashtag_word").forEach(el => el.remove());
        target.insertAdjacentHTML("afterbegin",content)
    }

    preview(id) {
        let target = document.getElementById(`hashtag_preview${id}`)
        let hashtagForm = document.getElementById(`hashtag_form${id}`);
        let previewArray = hashtagForm.value !== '' ? hashtagForm.value.split(',') : this.wordArray
        if (!target) {return}
        target.innerHTML = previewArray.map((word, index) =>
            `<div id=${index} class="hashtag_word">
                <div>#</div>
                <div>${word}</div>
            </div>`
        ).join('')
    }

}
