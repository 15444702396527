import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = [ "media" ]

  connect() {
  }

  toggle() {
    if (this.mediaTarget.style.display == "none") {
      this.mediaTarget.style.display = "block"
    } else {
      this.mediaTarget.style.display = "none"
    }
  }
}
