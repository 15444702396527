import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {maxSelections: { type: Number, default: 9 }}

  connect() {
    var controller = this;
    this.form =   $("#media-grid-form")[0];
    $(".media-grid-ele").each(function (index) {
      var mediaEle = $(this);
      if (mediaEle.hasClass("media-grid-ele-filler") == false) {
        mediaEle.click(function () {
          toggleSelect($(this),controller);
        });

        // Firefox specific behavior
        if(navigator.userAgent.toLowerCase().indexOf('firefox') > -1){
          mediaEle[0].addEventListener('play', function () {
            toggleSelect($(this),controller);
          }, true);
        }
      }
    });
    Sortable.create(document.getElementById("gridParent"), {
      delay: 500,
      delayOnTouchOnly: true
    });
  }
}
function toggleSelect(element,controller) {
  var input = element.children("input");
  var numberOfSelections = $("input[name='creator[reel_content][]']").length
  if (input.length == 0) {
    element.prepend("<input></input>")
    input = element.children("input");
    input.css("display", "none");
    input.attr("name", "creator[reel_content_ids][]");//.attr("data-input", "creator[reel_content_ids][]");
  }

  if (input.val() != "") {
    input.val("");
    element.css("border-style", "");
    element.css("border-color", "");
    element.css("border-width", "");
    input.attr("name", "");
    controller.form.requestSubmit();
  }
  else {
    if(numberOfSelections < controller.maxSelectionsValue){
      var mediaContent = {}
      mediaContent.permalink = element.attr("data-embed-link");
      mediaContent.media_url = element.find("video,img").attr("src");
      mediaContent.thumbnail_url = element.find("video").attr("poster");
      mediaContent.caption = element.find("img").attr("alt");
      mediaContent.media_id = element.attr("data-id");
      input.val(JSON.stringify(mediaContent));
      element.css("border-style", "solid");
      element.css("border-width", "thick");
      element.css("border-color", "var(--color-highlight)");
      input.attr("name", "creator[reel_content][]");
      controller.form.requestSubmit();
    }

  }
}
