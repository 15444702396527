import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "page" ]
  connect(){
    var params = {};
    params.indexValue = "0";
    this.showPage({ params });
  }

  showPage({ params }) {
    console.log("Set to ", params);
    var indexValue = Number(params.indexValue);
      this.pageTargets.forEach((element, index) => {
        element.hidden = index != indexValue
      })
    }
}
