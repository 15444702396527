import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["mainMedia", "mediaList", "insta", "fb"]

  connect() {
    // ! Timeout is required when we show media in popup and width is not defined yet
    setTimeout((() => {
      if (this.hasMediaListTarget) {
        this.selectMedia(this.mediaListTarget.children[0]);
      }

      if (this.hasInstaTarget) {
        this.initInstaPost();
      } else if (this.hasFbTarget) {
        this.initFbPost();
      }
    }).bind(this), 400);
  }

  initFbPost() {
    const url = this.fbTarget.dataset.href;
    // ! Almost the same as in job_result_form2_controller#fbResult
    if (!url.match(/www.facebook.com\/.*\/posts/)) { return; }
    const dataWidth = this.fbTarget.dataset.width;
    let width = dataWidth ? dataWidth : 500
    const parentWidth = Math.round($(this.fbTarget).parent().width());
    if (parentWidth) { width = Math.min(parentWidth, width); }
    const urlObj = new URL(`https://www.facebook.com/plugins/post.php?show_text=true&width=${width}`);
    urlObj.searchParams.append("href", url);
    const iframeUrl = urlObj.toString();

    this.fbIframe = $('<iframe src="' + iframeUrl + '" width="' + width + '" height="633" style="border: 1px solid rgb(219, 219, 219); border-radius: 3px; overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>').
      appendTo(this.fbTarget);
  }

  initInstaPost() {
    if (window.instgrm) {
      window.instgrm.Embeds.process();
    } else {
      $.ajax({
        url: "//www.instagram.com/embed.js",
        dataType: 'script',
        async: true
      });
    }
  }

  clickOnSmallImage(event) {
    this.selectMedia(event.target);
  }

  selectMedia(target) {
    if (!target) { return; }
    if (this.selectedImage) {
      this.selectedImage.classList.remove('active')
    }

    const asVideo = target.tagName == "VIDEO";
    if (asVideo) {
      this.mainMediaTarget.style.backgroundImage = "";
      this.mainMediaTarget.querySelector('video').style.display = '';
      this.mainMediaTarget.querySelector('video').src = target.src;
      this.mainMediaTarget.style.height = "auto";
    } else {
      this.mainMediaTarget.querySelector('video').style.display = "none";
      this.mainMediaTarget.style.height = "";
      this.mainMediaTarget.style.backgroundImage = target.style.backgroundImage;
    }

    this.selectedImage = target;
    this.selectedImage.classList.add('active');
    this.mainMediaTarget.style.display = "block";
    this.mainMediaTarget.style.backgroundImage = target.style.backgroundImage;
    this.mainMediaTarget.dataset.proof = target.dataset.proof
  }

  clickOnBigImage(event) {
    if (event.target.dataset.ghost == "true") {
      $.ajax({
        type: 'GET',
        dataType: 'script',
        url: `/single_creator_subjob_results/${event.target.dataset.state}/proof/`,
        data: {
          proof_id: event.target.dataset.proof
        }
      })
    }
  }

}
