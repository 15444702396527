import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = ["spinner" ]

  connect() {
    var spinner = this.spinnerTarget
    var element = this.
    this.element.addEventListener("ajax:complete", () => {
      spinner.style.display = "none";
      $(element).animate({
        left: "100%"
      }, 1000, easing, function() {
        $(element).parent().parent().remove();
      });
    });
    this.element.addEventListener("submit", () => {
      spinner.style.display = "block";
    });
  }

  disconnect(){
  }
}
