import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["item", "counter"]
  static values = {
    count: { type: Number, default: 0 },
    notes: { type: Number, default: 0 },
  }

  connect() {
    var theThis = this;
    $('#read-section').hide()
    $.ajax({
      type: 'GET',
      dataType: 'script',
      url: `/notifications/draw_read_list`,
    })
    this.counterTarget.textContent = this.countValue;
    this.showHideCounter();

    var noteList = document.getElementById('notifications__dropdown-list');
    if (noteList != null) {
      noteList.addEventListener('scroll',
      function()
      {
        theThis.scroller(noteList, '.notification__item', 'add_to_list', true);
      },
      false)
    }

    var readNoteList = document.getElementById('read_notifications__dropdown-list');
    if (readNoteList != null) {
      readNoteList.addEventListener('scroll', 
      function()
      {
        theThis.scroller(readNoteList, '.notification__item__read', 'add_to_read_list', false);
      },
      false)
    }

  }

  scroller(list, items, path, hide_notes) {
    var scrollTop = list.scrollTop + 1;
    var scrollHeight = list.scrollHeight;
    var offsetHeight = list.offsetHeight;
    var contentHeight = scrollHeight - offsetHeight;
    if (contentHeight <= scrollTop)
    {
      var note_count = document.querySelectorAll(items).length;
      if (hide_notes) {
        note_count =  note_count - document.querySelectorAll('.item__closed').length;
      }
      $.ajax({
        type: 'POST',
        dataType: 'script',
        url: `/notifications/`+path,
        data: {
          showed: note_count
        }
      })
    }
  }

  clickCloseButton(event) {
    event.stopPropagation();
    var select_note_id = "#note_" + event.target.dataset.id
    var item = document.querySelector(select_note_id);
    var $item = $(item);
    if (item.classList.contains("notification__item")) {
      item.classList.add('item__closed');
      this.readMsg(item);
      $item.slideUp(300);
      var note_count = document.querySelectorAll('.notification__item').length
      var open_count =  note_count - document.querySelectorAll('.item__closed').length;
      if (open_count < 6) {
        $.ajax({
          type: 'POST',
          dataType: 'script',
          url: `/notifications/add_to_list`,
          data: {
            showed: open_count
          }
      })
      }
    } else {
      document.activeElement.blur();
    }
  }

  clickLink(event) {
    var select_note_id = "#note_" + event.target.parentElement.dataset.id
    var item = document.querySelector(select_note_id);
    if (item.classList.contains("notification__item")) {
      this.readMsg(item, false);
    }
  }

  readMsg(item) {
    this.countValue = Math.max(this.countValue - 1, 0);
    this.counterTarget.textContent = this.countValue;
    this.showHideCounter();
    window.noteThreshold = 0;
    $.ajax({
        type: 'POST',
        dataType: 'script',
        url: `/notifications/${item.dataset.id}/read`
      });
  }

  showHideCounter() {
    if (this.countValue == 0) {
      this.counterTarget.style.display = 'none';
    } else {
      this.counterTarget.style.display = '';
    }
  }

  selectReadList(event) {
    event.stopPropagation();
    $('#read_notifications__dropdown-list').empty();
    $.ajax({
      type: 'GET',
      dataType: 'script',
      url: `/notifications/draw_read_list`,
    })
    $('#read-section').show();
    $('#button-read').addClass('active');
    $('#unread-section').hide();
    $('#button-unread').removeClass('active');
  }

  selectUnreadList(event) {
    event.stopPropagation();
    $('#read-section').hide();
    $('#button-read').removeClass('active');
    $('#unread-section').show();
    $('#button-unread').addClass('active');
  }

}
