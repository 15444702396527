import { Controller } from "@hotwired/stimulus"
import * as bootstrap from 'bootstrap'
export default class extends Controller {
  static values = { url: String, refreshInterval: Number}
  static targets = [ "content", "spinner"]

  connect() {
    if(this.hasUrlValue && this.urlValue.length > 0 ){
      if(this.hasRefreshIntervalValue){
        var theThis = this;
        this.intervalID = window.setInterval(function(){ theThis.load(theThis.urlValue, false)}, this.refreshIntervalValue + Math.random() * this.refreshIntervalValue);
      }
    }
    this.targetDict = {};
    for(var key in this.contentTargets){
      this.targetDict[this.contentTargets[key].getAttribute("data-content-update-id")] = this.contentTargets[key];
    }
  }

  disconnect(){
    window.clearInterval(this.intervalID);
  }

  load(url, showSpinner = true) {
    fetch(url)
      .then(response => response.text())
      .then(html => this.insertResponse(html))
  }

  insertResponse(html){
    this.showContent(html);

  }

  showContent(html){
    var theThis = this;
    $(html).children("[data-content-update-id]").each(function() {
      let element = theThis.targetDict[this.getAttribute("data-content-update-id")];
      element.innerHTML = this.innerHTML;
      theThis.activatePopovers(element);
    });
  }

  activatePopovers(element){
    var popoverTriggerList = [].slice.call(element.querySelectorAll('[data-bs-toggle="popover"]'))
    var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
      var popover = new bootstrap.Popover(popoverTriggerEl, {html: true, sanitize: false});
      return popover;
    })
  }
}
