import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    this.sort()
  };

  sort() {
    var appliedList = $(".sort-list-item");
    var sortDataParam = $("#sort :selected").val();
    appliedList.sort(function(a, b){ return $(a).data(sortDataParam)-$(b).data(sortDataParam)});
    $("#applied_list").html(appliedList);
  };
}
