import { Controller } from 'stimulus'; 

export default class extends Controller {
  static targets = ["heading", "name"]

  connect() {
    console.log("hello from StimulusJS")
  }

  greet() {
    console.log("click greet", this)


    console.log(`Hello, ${name}`)
    this.headingTarget.innerHTML = "Hello World" + this.name
  }

  get name() {

    name = "none"

    console.log("hasNameTarget ?", this.hasNameTarget)
    if (this.hasNameTarget) {
      const element = this.nameTarget
      name = element.value
    } else {
      console.log("no element")
    }
    return name
  }

  disconnect() {
    console.log("we have a disconnect")
  }
}
