import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { numberOfResults: Number }
  static targets = ["numberRated"]

  connect() {
    this.totalRated = 0;
    this.setNumberRated();
  }

  resultRated(){
    this.totalRated++;
    this.setNumberRated();
  }

  setNumberRated(){
    this.numberRatedTarget.textContent = this.totalRated + "/" + this.numberOfResultsValue;
  }
}
