import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["sendEmailButton"]

  connect() {
    this.emailArray = []
  }

  change(event) {
    if (event.target.checked) {
      this.emailArray.push(event.target.dataset.email)
    } else {
      this.emailArray.splice(this.emailArray.indexOf(event.target.dataset.email), 1)
    }
    this.sendEmailButtonTarget.href = 'mailto:'+ this.emailArray.join()
    if (this.emailArray.length > 0) {
      this.sendEmailButtonTarget.classList.remove('hidden')
    } else {
      this.sendEmailButtonTarget.classList.add('hidden')
    }
  }
}