import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { collapseId: String, event: {type: String, default: "show.bs.collapse"} }

  connect() {
    this.collapse = document.getElementById(this.collapseIdValue);
    var theThis = this;
    this.eventId = this.collapse.addEventListener(this.eventValue, function () {
      var src = theThis.element.src;
      theThis.element.src = null;
      theThis.element.src = src;
    })
  }

  disconnect(){
    this.collapse.removeEventListener(this.eventValue,this.eventId);
  }
}
