import {Controller} from 'stimulus';

export default class extends Controller {
    static targets = ["artifacts", "delBtn", "visibilityLabel"]
    static values = { size: Number }

    addArtifact(event) {
        event.preventDefault()
        this.sizeValue += 1;
        const regex = /\[\d+]/g;
        const regex_id = /_\d+_/g;
        const new_name = `[${this.sizeValue}]`;
        const new_id = `_${this.sizeValue}_`;
        let firstElement = this.artifactsTarget.firstElementChild
        const raw = firstElement.outerHTML.replace(regex, new_name).replace(regex_id, new_id).replace(/:.+?</, '<')
        this.artifactsTarget.insertAdjacentHTML("beforeend", raw);
        const newFieldset = this.artifactsTarget.lastElementChild;
        newFieldset.dataset.index = this.sizeValue;
        newFieldset.classList.remove("d-none");
        this.checkButtons()
    }

    checkButtons() {
        if (this.sizeValue > 0) {
            this.delBtnTargets.forEach(function (button, index) {
                if (index == 0) { return }
                button.classList.remove("d-none");
            })
        }
    }

    changeVisibility(event) {
        const index = event.target.closest("fieldset").dataset.index;
        const dataset = this.visibilityLabelTarget.dataset;
        this.visibilityLabelTargets[index].innerHTML = event.target.checked ? dataset.hidden : dataset.visible;
    }

    delArtifact(event) {
        const delFieldset = event.target.closest("fieldset");
        console.log("DELETE", delFieldset.dataset.id)
        if (delFieldset.dataset.id) {
            const delInput = delFieldset.nextElementSibling;
            delInput.remove();
            $.ajax({
                type: 'DELETE',
                dataType: 'html',
                url: `/task_artifacts/${delFieldset.dataset.id}`
            })
        }
        delFieldset.remove();
        this.sizeValue -= 1;
        event.preventDefault()
    }
}
