document.addEventListener("turbo:render", setupValidation);
document.addEventListener("turbo:load", setupValidation);

function setupValidation(event) {
  document.errors = new Map();
  document.pageErrors = new Map();
  // $("form").each(function(){
  //   var formErrors = new Map();
  //   document.errors.set($(this), formErrors);
  // });
  var textControlSelector = "textarea[data-validateMaxChars], textarea[data-validateMinChars], textarea[data-validateTextPattern], input[data-validateMaxChars], input[data-validateMinChars], input[data-validateTextPattern]";
  $(textControlSelector).on("input", function () {
    validateText($(this));
  });
  //$(textControlSelector).each(function(){
  //  validateText($(this));
  //});
  $("input[data-validateMaxNumber], input[data-validateMinNumber]").on("input", function () {
    validateNumber($(this));
  });
  //$("input[data-validateMaxNumber], input[data-validateMinNumber]").each(function(){
  //  validateNumber($(this));
  //});

  $("[data-dependent]").on("change", function () {
    validateDependents();
  });
  //$( "[data-dependent]" ).each(function() {
  //  validateDependents();
  //});

  $(document).on('change', 'div[data-validateMinNumberOfTags][data-validateMaxNumberOfTags] input:checkbox', function () {
    validateNumberOfTags($(this));
  });
  //$("div[data-validateMinNumberOfTags], div[data-validateMaxNumberOfTags]").find('input:checkbox').each(function(){
  //  validateNumberOfTags($(this));
  //});

}

function errorPrototype() {
  return { value: false, message: "", pageNumber: "" };
}

function validateDependents() {
  $("[data-dependent]").each(function () {
    $($(this).attr("data-dependent")).each(function () {
      if (this.hasAttribute("data-validateMinChars")
        || this.hasAttribute("data-validateMaxChars")
        || this.hasAttribute("data-validateTextPattern")) {
        validateText($(this));
      }
      if (this.hasAttribute("data-validateMinNumber")
        || this.hasAttribute("data-validateMaxNumber")) {
        validateNumber($(this));
      }
      if (this.hasAttribute("data-validateMaxNumberOfTags")
        || this.hasAttribute("data-validateMinNumberOfTags")) {
        validateNumberOfTags($(this));
      }
    });
  });

}

function validateForm(trigger, errors) {
  var formErrors = document.errors;
  //console.log("Form " +  JSON.stringify(formErrors));
  formErrors.set(trigger.attr('id'), errors);
  //console.log("Errors is " + Array.from(formErrors.keys()).length);
  var allErrors = [];
  for (let [key, value] of formErrors) {
    allErrors = allErrors.concat(value);
  }
  //console.log("All errors " + allErrors);
  let page = trigger.closest("[data-page-number]");
  if (page.length > 0) {
    let pageNumber = page.attr("data-page-number");
    for (var error of errors) {
      error.pageNumber = pageNumber;
    }
    let hasErrorOnPage = allErrors.some(function callbackFn(error) { return error.value && error.pageNumber == pageNumber; });
    let pageIndicator = $("#page-indicator-" + pageNumber);
    let indicatorImage = pageIndicator[0].children.item(0).children.item(0);
    indicatorImage.style.display = hasErrorOnPage ? "block" : "none";
  }

  var submitButton = $("input[formsubmit]");
  if (allErrors.some(function callbackFn(error) { return error.value; })) {
    submitButton.prop('disabled', true);
    //console.log("form is invalid");
    submitButton.addClass("validation-failed");
  }
  else {
    submitButton.prop('disabled', false);
    //console.log("form is alright");
    submitButton.removeClass("validation-failed");

  }
}

function updateCharsLeft(element) {
  //console.log("Id " + element.attr("id"))
  var charsLeftElement = element.siblings(".characters-left");
  var charsLeft = element.attr("maxlength") - element.val().length;
  charsLeftElement.text(charsLeft + ' ' +I18n["validation"]["characters_left"]);
}

function validateMaxChars(element) {
  var error = errorPrototype();
  if (element.val().length > element.attr("maxlength")) {
    error.value = true;
    error.message = I18n["validation"]["text_too_long"];
  }
  return error;
}

function validateMinChars(element) {
  var error = errorPrototype();
  if (element.val().length < element.attr("data-validateMinChars")) {
    error.value = true;
    error.message = I18n["validation"]["text_too_short"];
  }
  return error;
}

function validateTextPattern(element) {
  var pattern = element.attr("data-validateTextPattern");
  var regexp = new RegExp(pattern);
  var error = errorPrototype();
  if (!regexp.test(element.val())) {
    error.value = true;
    error.message = I18n["validation"]["text_contains_invalid_characters"];
    //console.log(pattern + " " + error.message);
  }
  return error;
}

function validateText(element) {
  var attr = element.attr('data-dependent-on');
  if (typeof attr !== typeof undefined && attr !== false && !$("#" + attr).prop("checked")) {
    validateForm(element, []);
    return;
  }
  var errors = [];
  if (element[0].hasAttribute("data-validateMinChars")) {
    errors.push(validateMinChars(element));
  }
  if (element[0].hasAttribute("maxlength")) {
    errors.push(validateMaxChars(element));
    updateCharsLeft(element);
  }
  if (element[0].hasAttribute("data-validateTextPattern")) {
    errors.push(validateTextPattern(element));
  }
  insertErrors(errors, element.parent(), element);
  validateForm(element, errors);
}


function validateNumber(element) {
  var attr = element.attr('data-dependent-on');
  if (typeof attr !== typeof undefined && attr !== false && !$("#" + attr).prop("checked")) {
    validateForm(element, []);
    return;
  }

  var errors = [];
  if (element[0].hasAttribute("data-validateMinNumber")) {
    errors.push(validateMinNumber(element));
  }
  if (element[0].hasAttribute("data-validateMaxNumber")) {
    errors.push(validateMaxNumber(element));
  }
  insertErrors(errors, element.parent(),element);
  validateForm(element, errors);
}

function validateMaxNumber(element) {
  var currentValue = Number(element.val());
  var maxValue = Number(element.attr("data-validateMaxNumber"));
  var error = errorPrototype();
  if (currentValue > maxValue) {
    error.value = true;
    error.message = I18n["validation"]["value_smaller_or_equal"] + maxValue;
  }
  return error;
}

function validateMinNumber(element) {
  var currentValue = Number(element.val());
  var minValue = Number(element.attr("data-validateMinNumber"));
  var error = errorPrototype();
  if (currentValue < minValue) {
    error.value = true;
    error.message = I18n["validation"]["value_greater_or_equal"] + minValue;
  }
  return error;
}

function validateNumberOfTags(element) {
  var attr = element.attr('data-dependent-on');
  if (typeof attr !== typeof undefined && attr !== false && !$("#" + attr).prop("checked")) {
    validateForm(element, []);
    return;
  }

  var parent = element.parents("div[data-validateMinNumberOfTags], div[data-validateMaxNumberOfTags]");
  var errors = [];
  if (parent[0].hasAttribute("data-validateMinNumberOfTags")) {
    errors.push(validateMinNumberOfTags(element));
  }
  if (parent[0].hasAttribute("data-validateMaxNumberOfTags")) {
    errors.push(validateMaxNumberOfTags(element));
  }
  insertErrors(errors, parent,element);
  validateForm(parent, errors);
}

function insertErrors(errors, parent, element) {
  let submitButton = $('[type="submit"]');
  if (errors.some(function callbackFn(error) { return error.value; })) {
    parent.addClass("validation-failed");
    var errorMessage = "";
    errors.forEach(function (entry) {
      if (entry.value) {
        errorMessage += "<li class='error'>" + entry.message + "</li>";
      }
    });
    parent.children(".errors").html(errorMessage);
    element.addClass("is-invalid-main")
    submitButton.prop("disabled", true);
  }
  else {
    element.removeClass("is-invalid-main")
    parent.removeClass("validation-failed");
    parent.children(".errors").html("");
    submitButton.prop("disabled", false);
  }
  parent.children(".server-errors").html("");
}

function validateMinNumberOfTags(element) {
  var parent = element.parents("div[data-validateMinNumberOfTags]");
  var minValue = Number(parent.attr("data-validateMinNumberOfTags"));
  var currentValue = parent.find('input:checkbox:checked').length;
  var error = errorPrototype();
  if (currentValue < minValue) {
    error.value = true;
    error.message = I18n["validation"]["not_enough_tags"];
  }
  else {
    error.value = false
  }
  return error;
}

function validateMaxNumberOfTags(element) {
  var parent = element.parents("div[data-validateMaxNumberOfTags]");
  var maxValue = Number(parent.attr("data-validateMaxNumberOfTags"));
  var currentValue = parent.find('input:checkbox:checked').length;
  var error = errorPrototype();
  //console.log("max tags " + currentValue + " " + maxValue);
  if (currentValue > maxValue) {
    error.value = true;
    error.message = I18n["validation"]["too_many_tags"];
  }
  else {
    error.value = false;
  }
  return error;
}
