import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  reference(event){
    let container = document.querySelector("#media-reference-container");
    let element = container.querySelector("[data-media-id='" + event.srcElement.getAttribute("data-media-id") + "']");
    if(element != null){
      return;
    }
    let mediaId = event.srcElement.getAttribute("data-media-id");
    let template = $("#reference-media-template")[0];
    let clone = template.content.cloneNode(true);
    console.log("src el ", event.srcElement);
    clone.querySelector("img").src = event.srcElement.getAttribute("data-src");
    clone.querySelector("div").setAttribute("data-media-id", mediaId);
    container.appendChild(clone);
    let fieldTemplate = $("#media-reference-field-template")[0];
    let fieldClone = fieldTemplate.content.cloneNode(true);
    fieldClone.querySelector("input").value = mediaId;
    fieldClone.querySelector("input").setAttribute("data-media-id", mediaId);
    document.querySelector("#reference-media-hidden-fields").appendChild(fieldClone);
  }
}
