export const convertRemToPixels = function(rem) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

export const convertPixelsToRem = function(px) {
    return parseFloat(getComputedStyle(document.documentElement).fontSize) / px;
}

export const lerp = function(start, end, amt){
  return (1-amt)*start+amt*end
}
