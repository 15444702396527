import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["updates", "daily", "email", "push"]

  connect() {
  }

  checkUpdate() {
    if (!this.dailyTarget.checked && !this.updatesTarget.checked) {
      this.emailTarget.checked = false;
      this.pushTarget.checked = false;
    }
  }

  checkDaily() {
    if (this.dailyTarget.checked) {
      this.emailTarget.checked = true;
    };
    if (!this.dailyTarget.checked && !this.updatesTarget.checked) {
      this.emailTarget.checked = false;
      this.pushTarget.checked = false;
    };
  }

  checkEmail() {
    if (!this.emailTarget.checked) {
      this.dailyTarget.checked = false;
      if (!this.pushTarget.checked) {
        this.updatesTarget.checked = false;
      }
    }    
  }

  checkPush() {
    if (!this.pushTarget.checked && !this.emailTarget.checked) {
      this.dailyTarget.checked = false;
      this.updatesTarget.checked = false;
    }
  }

}