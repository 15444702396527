window.containerSize = function () {
  let edit_container = document.getElementsByClassName("edit-height")[0]
  if (edit_container == null) { return }
  let main_container = document.getElementsByClassName("container-xxl")[1]
  let profile_container = document.getElementsByClassName("profile__body")[0]
  let min_height = main_container.offsetHeight
  let padding = parseInt(window.getComputedStyle(main_container, null).getPropertyValue('padding-top'), 10)
  let padding2 = parseInt(window.getComputedStyle(profile_container, null).getPropertyValue('padding-top'), 10)
  edit_container.style.minHeight = `${min_height-(padding+padding2)*2}px`;
}

var autocomplete;
var address1Field;
var address2Field;
var postalField;
var autocompleteBilling;
var address1FieldBilling;
var address2FieldBilling;
var postalFieldBilling;

async function initAutocomplete() {
  address1Field = document.querySelector("#profile-address #ship-address");
  address2Field = document.querySelector("#profile-address #address2");
  postalField = document.querySelector("#profile-address #postcode");
  address1FieldBilling = document.querySelector("#billing_address #ship-address");
  address2FieldBilling = document.querySelector("#billing_address #address2");
  postalFieldBilling = document.querySelector("#billing_address #postcode");
  const { Autocomplete } = await google.maps.importLibrary("places");
  autocomplete = new Autocomplete(address1Field, {
    componentRestrictions: { country: ["ch"] },
    fields: ["address_components", "geometry"],
    types: ["address"],
  });
  autocomplete.addListener("place_changed", fillInAddress);
  if (address1FieldBilling != null) {
    autocompleteBilling = new google.maps.places.Autocomplete(address1FieldBilling, {
      componentRestrictions: { country: ["ch"] },
      fields: ["address_components", "geometry"],
      types: ["address"],
    });
    autocompleteBilling.addListener("place_changed", fillInBillingAddress);
  }
}

function fillInAddress() {
  // Get the place details from the autocomplete object.
  const place = autocomplete.getPlace();
  let address1 = "";
  let postcode = "";

  // Get each component of the address from the place details,
  // and then fill-in the corresponding field on the form.
  // place.address_components are google.maps.GeocoderAddressComponent objects
  // which are documented at http://goo.gle/3l5i5Mr
  for (const component of place.address_components) {
    // @ts-ignore remove once typings fixed
    const componentType = component.types[0];

    switch (componentType) {
      case "street_number": {
        address1 = `${address1} ${component.long_name}`;
        break;
      }

      case "route": {
        address1 = component.short_name + address1;
        break;
      }

      case "postal_code": {
        postcode = `${component.long_name}${postcode}`;
        break;
      }

      case "postal_code_suffix": {
        postcode = `${postcode}-${component.long_name}`;
        break;
      }
      case "locality":
        document.querySelector("#locality").value = component.long_name;
        break;
      case "administrative_area_level_1": {
        // document.querySelector("#state").value = component.short_name;
        break;
      }
      case "country":
        document.querySelector("#country").value = component.long_name;
        break;
    }
  }
  address1Field.value = address1;
  postalField.value = postcode;
}

function fillInBillingAddress() {
  const place = autocompleteBilling.getPlace();
  let address1 = "";
  let postcode = "";

  for (const component of place.address_components) {

    const componentType = component.types[0];

    switch (componentType) {
      case "street_number": {
        address1 = `${address1} ${component.long_name}`;
        break;
      }

      case "route": {
        address1 = component.short_name + address1;
        break;
      }

      case "postal_code": {
        postcode = `${component.long_name}${postcode}`;
        break;
      }

      case "postal_code_suffix": {
        postcode = `${postcode}-${component.long_name}`;
        break;
      }
      case "locality":
        document.querySelector("#billing_address #locality").value = component.long_name;
        break;
      case "administrative_area_level_1": {
        // document.querySelector("#state").value = component.short_name;
        break;
      }
      case "country":
        document.querySelector("#billing_address #country").value = component.long_name;
        break;
    }
  }
  address1FieldBilling.value = address1;
  postalFieldBilling.value = postcode;
}

window.initAutocomplete = initAutocomplete;