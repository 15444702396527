import { Controller } from "stimulus";
const forLink = ["subscription", "booking", "order"]
const forCode = ["booking", "order"]

export default class extends Controller {
  static targets = ["kind"]

  connect() {
    this.showHideFormFields()
  }

  kindChange() {
    this.showHideFormFields()
  }

  showHideFormFields() {
    this.showHideField(forLink, $("#link_column"))
    this.showHideField(forCode, $("#code_column"))
  }

  showHideField(allowedValues, field) {
    if (allowedValues.includes(this.kindTarget.value)) {
      field.show()
    } else {
      field.hide()
      field.children('input').first().val('')
    }
  }
}