import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { class: String }

  connect() {
  }

  disconnect(){
    this.element.classList.add(this.classValue);
  }
}
