import { Controller } from "@hotwired/stimulus"
import * as bootstrap from 'bootstrap'
const imgFileTypes = ["image/jpeg", "image/png", "image/gif"]
const videoFileTypes = ["video/mp4","video/ogg","video/m4v"]

export default class extends Controller {
  static targets = ["image", "video", "collapsible","input"]

  connect(){
    var theThis = this;
    this.element.addEventListener("ajax:success", (event,xhr) => {
      theThis.onsuccess(event);
    });
    this.element.addEventListener("ajax:error", () => {
      console.log("Error", event);
    });
    let bsCollapse = new bootstrap.Collapse(this.collapsibleTarget, {
      toggle: false
    });
    this.collapse = bsCollapse;
  }

  changed(event){
    if(imgFileTypes.includes(event.target.files[0].type)){
      this.imageTarget.setAttribute("src", URL.createObjectURL(event.target.files[0]));
      this.imageTarget.style.display = "block";
      this.videoTarget.style.display = "none";
    }
    if(videoFileTypes.includes(event.target.files[0].type)){
      this.videoTarget.setAttribute("src", URL.createObjectURL(event.target.files[0]));
      this.videoTarget.style.display = "block";
      this.imageTarget.style.display = "none";
    }
    this.collapse.show();
  }

  onsuccess(event){
    if(event.detail[0].id == null){
      return;
    }
    let mediaId = event.detail[0].id;
    let container = document.querySelector("#media-reference-container");
    let element = container.querySelector("[data-media-id='" + mediaId + "']");
    if(element != null){
      return;
    }
    let template = $("#reference-media-template")[0];
    let clone = template.content.cloneNode(true);
    clone.querySelector("img").src = event.detail[0].url;
    clone.querySelector("div").setAttribute("data-media-id", mediaId);
    container.appendChild(clone);
    let fieldTemplate = $("#media-reference-field-template")[0];
    let fieldClone = fieldTemplate.content.cloneNode(true);
    fieldClone.querySelector("input").value = mediaId;
    fieldClone.querySelector("input").setAttribute("data-media-id", mediaId);
    document.querySelector("#reference-media-hidden-fields").appendChild(fieldClone);

  }

  clear(){
    this.inputTarget.value = "";
    this.videoTarget.style.display = "none";
    this.imageTarget.style.display = "none";
    this.videoTarget.setAttribute("src", "");
    this.imageTarget.setAttribute("src", "");

  }

}
