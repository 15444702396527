import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { url: String, page: String, selectedJobs: Array, tab: String }

  connect() {
  };

  itemClicked(event){
    let job_ids = []
    let job_id = event.params.id;
    if(this.hasSelectedJobsValue){
      job_ids = this.selectedJobsValue;
    }
    job_id = job_id.toString();
    if(!job_ids.includes(job_id)){
       job_ids.push(job_id);
    } else{
      let index = job_ids.indexOf(job_id);
      job_ids.splice(index, 1);
    }

    let url = new URL(this.urlValue);
    url.searchParams.delete('selected_job_ids');
    url.searchParams.delete('selected_job_ids[]');
    if(job_ids.length == 1){
      job_ids.forEach(el =>  url.searchParams.append('selected_job_ids', el))
    }
    else{
      job_ids.forEach(el =>  url.searchParams.append('selected_job_ids[]', el))
    }
    url.searchParams.set('page', this.pageValue)
    url.searchParams.set('tab', this.tabValue)

    Turbo.visit(url)
  }

}
