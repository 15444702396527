import { Controller } from "stimulus";

export default class extends Controller {
    static values = { showText: String, hideText: String }

    connect() {
        this.updateButtonText();
    }

    toggleCollapse() {
        document.querySelector(".other-creators").classList.toggle('displayed')
        this.updateButtonText();
    }

    updateButtonText() {
        const isExpanded = document.querySelector(".other-creators").classList.contains('displayed');
        const button = document.querySelector('#button-collapse')
        if (isExpanded) {
            button.textContent = this.hideTextValue;
        } else {
            button.textContent = this.showTextValue;
        }
    }
}
