import { Controller } from "@hotwired/stimulus"
import * as bootstrap from 'bootstrap'

export default class extends Controller {
  static values = { url: String, consumer: String, refreshInterval: Number, classToToggle: String}
  static targets = [ "content", "spinner"]

  connect() {
    if(this.hasConsumerValue){
      var consumer = this.element.closest("[data-controller=" + this.consumerValue + "]");
      var controller = this.application.getControllerForElementAndIdentifier(consumer, this.consumerValue);
      controller.setAsyncLoader(this);
      this.consumer = controller;
    }
    if(this.hasUrlValue && this.urlValue.length > 0 ){
      this.load(this.urlValue)
      if(this.hasRefreshIntervalValue){
        var theThis = this;
        this.intervalId = window.setInterval(function(){ theThis.load(theThis.urlValue)}, this.refreshIntervalValue);
      }
    }
  }

  disconnect(){
    window.clearInterval(this.intervalId);
  }

  load(url) {
    this.contentTarget.style.display = "none";
    if(this.hasSpinnerTarget){
      this.spinnerTarget.style.display = "block";
    }
    //console.log("url", params.url);
    fetch(url)
      .then(response => {
        if (!response.ok) {
          if(this.consumer)
          {
            this.consumer.asyncContentError();
          }
          throw new Error('Network response was not OK');
        }
        return response.text();
      })
      .then(html => this.insertResponse(html))
  }

  insertResponse(html){
    this.showContent(html);
    if(this.consumer)
    {
      this.consumer.asyncContentLoaded();
    }
  }

  showContent(html){
    //console.log(this.hasClassToToggleValue, this.classToToggleValue);
    if(this.hasClassToToggleValue){
      this.element.classList.toggle(this.classToToggleValue);
    }
    this.contentTarget.innerHTML = html;
    this.contentTarget.style.display = "block";
    this.activatePopovers(this.contentTarget);
    if(this.hasSpinnerTarget){
      this.spinnerTarget.style.display = "none";
    }
  }

  activatePopovers(element){
    var popoverTriggerList = [].slice.call(element.querySelectorAll('[data-bs-toggle="popover"]'))
    var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
      var popover = new bootstrap.Popover(popoverTriggerEl, {html: true, sanitize: false});
      return popover;
    })
  }
}
